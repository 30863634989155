import { useState, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useResizableSidebar = (initialWidth = 400, minWidth = 4, storageKey = 'sidebarWidth') => {
  const getInitialWidth = () => {
    const storedWidth = localStorage.getItem(storageKey);
    return storedWidth ? parseInt(storedWidth, 10) : initialWidth;
  };

  const [sidebarWidth, setSidebarWidth] = useState(getInitialWidth); // initialWidth 初始宽度
  const [isResizerVisible, setIsResizerVisible] = useState(false);
  const initialWidthRef = useRef(sidebarWidth);
  const startXRef = useRef(0);
  const handleMouseDown = (e: { preventDefault: () => void; clientX: number }) => {
    e.preventDefault();
    startXRef.current = e.clientX;
    initialWidthRef.current = sidebarWidth;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: { clientX: number }) => {
    const deltaX = e.clientX - startXRef.current;
    const newWidth = initialWidthRef.current + deltaX;
    setSidebarWidth(newWidth >= minWidth ? newWidth : minWidth); //防止滑动的没有了
    localStorage.setItem(storageKey, (newWidth >= minWidth ? newWidth : minWidth).toString());
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseEnter = () => setIsResizerVisible(true);
  const handleMouseLeave = () => setIsResizerVisible(false);
  return {
    sidebarWidth,
    handleMouseDown,
    handleMouseEnter,
    handleMouseLeave,
    isResizerVisible,
  };
};

export default useResizableSidebar;
