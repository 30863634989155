import { FETCHING_STATUS } from '@/constants';
import { SerializedError } from '@reduxjs/toolkit';
export interface IPagination {
  pageSize: number;
  page: number;
  total: number;
}

export type ListStatus<T> = {
  list: Record<string, T>;
  pagination?: IPagination;
  status: FETCHING_STATUS;
  error?: SerializedError;
};
export type ListArrayStatus<T> = {
  list: T[];
  pagination?: IPagination;
  status: FETCHING_STATUS;
  error?: SerializedError;
  hasMore?: boolean;
};
export type ItemStatus<T> = {
  item: T;
  status: FETCHING_STATUS;
  error?: SerializedError;
  timestamp?: number;
};

export const getDefaultListStatus = <T>(status = FETCHING_STATUS.INIT): ListStatus<T> => {
  return {
    list: {},
    status: status,
  };
};
export const getDefaultListArrayStatus = <T>(status = FETCHING_STATUS.INIT): ListArrayStatus<T> => {
  return {
    list: [],
    status: status,
  };
};
export const getDefaultItemStatus = <T>(status = FETCHING_STATUS.INIT): ItemStatus<T | undefined> => {
  return {
    item: undefined,
    status: status,
  };
};
export const setFulfilledListStatus = <T>(list: Record<string, T>, pagination?: IPagination): ListStatus<T> => {
  return {
    list,
    status: FETCHING_STATUS.DONE,
    pagination,
  };
};
export const setFulfilledEndListStatus = <T>(list: Record<string, T>, pagination?: IPagination): ListStatus<T> => {
  return {
    list,
    status: FETCHING_STATUS.COMPLETED,
    pagination,
  };
};

export const setFulfilledListArrayStatus = <T>(list: T[], pagination?: IPagination): ListArrayStatus<T> => {
  return {
    list,
    pagination,
    status: FETCHING_STATUS.DONE,
    hasMore: true,
  };
};
export const setFulfilledBeginListArrayStatus = <T>(list: T[], pagination?: IPagination): ListArrayStatus<T> => {
  return {
    list,
    status: FETCHING_STATUS.BOTTOM,
    hasMore: true,
    pagination,
  };
};
export const setFulfilledEndListArrayStatus = <T>(list: T[], pagination?: IPagination): ListArrayStatus<T> => {
  return {
    list,
    status: FETCHING_STATUS.COMPLETED,
    hasMore: false,
    pagination,
  };
};
export const setFulfilledNoNewListArrayStatus = <T>(list: T[], pagination?: IPagination): ListArrayStatus<T> => {
  return {
    list,
    status: FETCHING_STATUS.NO_NEW_DATA,
    pagination,
  };
};
export const setFulfilledItemStatus = <T>(item: T): ItemStatus<T> => {
  return {
    item,
    status: FETCHING_STATUS.DONE,
  };
};

export type ObjectStatus<T> = Partial<T> & {
  status: FETCHING_STATUS;
  error?: SerializedError;
};

export const getDefaultObjectStatus = (status = FETCHING_STATUS.INIT): { status: FETCHING_STATUS } => {
  return {
    status: status,
  };
};

export const setFulfilledObjectStatus = <T>(obj: T): ObjectStatus<T> => {
  return {
    ...obj,
    status: FETCHING_STATUS.DONE,
  };
};
