export enum INBOX_SOURCES_KEY {
  ALL = 'All',
  GMAIL = 'gmail',
  META = 'meta',
  STORE_PLUGIN = 'store_plugin',
}

export const USER_PAGE_SIZE = 30;

export enum COMMENT_MODES {
  DISPLAY = 'display',
  EDIT = 'edit',
}
export enum PLUGIN_TABS {
  SET_UP = 'setup',
  INBOX = 'inbox',
  CANCEL_REQUEST = 'cancel_requests',
}
export enum MAIL_BTN_TYPE {
  REPLY = 'REPLY',
  NEW = 'NEW',
}
export enum MAIL_BOX_TYPE {
  INBOX = 'inbox',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
}
