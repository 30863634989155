import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  ListArrayStatus,
  ListStatus,
  // getDefaultListStatus,
  setFulfilledBeginListArrayStatus,
  setFulfilledEndListArrayStatus,
  setFulfilledNoNewListArrayStatus,
  setFulfilledListArrayStatus,
  setFulfilledEndListStatus,
  setFulfilledListStatus,
  IPagination,
} from '@/types/redux';
import { AppState } from '@/features/store';
import { IMailbox, IMailBoxFilter, IMailboxUser, IMetaComment, IMetaPost } from '@/types/inbox';
import { INBOX_SOURCES_KEY } from '@/constants/inbox';
import { IMail } from '@/types/email';
import { ERROR_CODE, FETCHING_STATUS } from '@/constants';

import {
  getMailboxList,
  getUserMailList,
  setInboxCurrentId,
  setInboxCurrentText,
  setMailboxFilter,
  setInboxSourceType,
  sendMail,
  getAigcResponse,
  setEmailRender,
  getArchivedById,
  setInboxDrawerOpen,
  setInboxReplying,
  setAigcResponse,
  getMetaPosts,
  getMetaPostComments,
  setInboxPostSearchStr,
  setInboxCurrentPostId,
  setInboxCommentSearchStr,
  getMetaPostCommentReplies,
  setInboxCurrentPostComment,
  ReplyMetaPostComment,
  setAigcReplyResponse,
  setInboxMetaCurrentText,
  setAigcCommentReplies,
  setPostCommentSelected,
  setAigcCommentReply,
  resetMeta,
  sendDirectMail,
  getMailUserList,
} from './actions';

export interface IInboxState {
  orgMailboxList: { [orgId: string]: ListArrayStatus<IMailbox> };
  orgMailUserList: { [orgId: string]: ListArrayStatus<IMailboxUser> };
  orgCurrentMailboxId: { [orgId: string]: string };
  orgUserMails: { [orgId: string]: { [userEmail: string]: ListArrayStatus<IMailbox> } };
  orgInboxSourceType: { [orgId: string]: INBOX_SOURCES_KEY };
  orgInboxMailboxFilter: { [orgId: string]: IMailBoxFilter };
  orgUserCurrentText: { [orgId: string]: { [user: string]: string } };
  orgMetaPosts: { [orgId: string]: ListStatus<IMetaPost> };
  orgMetaPostComments: { [orgId: string]: { [postId: string]: ListStatus<IMetaComment> } };
  sendingMail: { [address: string]: boolean };
  generatingAigcResponse: boolean;
  drawerOpen: boolean;
  replying: boolean;
  aigcResponse: { [mailId: string]: string };
  emailRenderStatus: { [mailId: string]: string };
  orgUserSentEmail: { [orgId: string]: { [user: string]: number } };
  orgInboxPostSearchStr: { [orgId: string]: string };
  orgCurrentPostId: { [orgId: string]: string };
  orgInboxCommentSearchStr: { [orgId: string]: string };
  orgMetaPostCommentReplies: {
    [orgId: string]: { [commentId: string]: ListStatus<IMetaComment> };
  };
  orgCurrentPostComment: {
    [orgId: string]: IMetaComment;
  };
  replyingComment: { [id: string]: boolean };
  aigcReplyResponse: { [commentId: string]: string };
  orgPostCurrentText: { [orgId: string]: { [post: string]: string } };
  gettingAigcReplyResponse: boolean;
  commentAigcReplies: {
    [commentId: string]: string;
  };
  postSelectedComments: {
    [postId: string]: string[];
  };
}

const initialState: IInboxState = {
  orgMailboxList: {},
  orgMailUserList: {},
  orgUserMails: {},
  orgInboxSourceType: {},
  orgInboxMailboxFilter: {},
  orgCurrentMailboxId: {},
  orgUserCurrentText: {},
  sendingMail: {},
  drawerOpen: false,
  replying: false,
  generatingAigcResponse: false,
  aigcResponse: {},
  emailRenderStatus: {},
  orgUserSentEmail: {},
  orgMetaPosts: {},
  orgMetaPostComments: {},
  orgInboxPostSearchStr: {},
  orgCurrentPostId: {},
  orgInboxCommentSearchStr: {},
  orgMetaPostCommentReplies: {},
  orgCurrentPostComment: {},
  replyingComment: {},
  aigcReplyResponse: {},
  orgPostCurrentText: {},
  gettingAigcReplyResponse: false,
  commentAigcReplies: {},
  postSelectedComments: {},
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    // spliceUserListByFrom: (state, action: PayloadAction<{ from: string; orgId: string }>) => {
    //   const { orgId, from } = action.payload;
    //   const current = _.get(state.orgMailboxList, [orgId, 'list']) || {};
    //   _.unset(current, from);
    //   const filledListStatus = setFulfilledEndListStatus(current);
    //   _.set(state.orgMailboxList, [orgId], filledListStatus);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMailboxList.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMailboxList, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMailboxList.fulfilled, (state, { meta, payload }) => {
        const { orgId } = meta.arg;
        if (payload) {
          _.set(state.orgMailboxList, [orgId], setFulfilledListArrayStatus(payload.data, payload.pagination));
        }
      })
      .addCase(getMailUserList.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMailUserList, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMailUserList.fulfilled, (state, { meta, payload }) => {
        const { orgId } = meta.arg;
        if (payload) {
          _.set(state.orgMailUserList, [orgId], setFulfilledListArrayStatus(payload.data, payload.pagination));
        }
      })
      .addCase(getArchivedById.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMailboxList, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getArchivedById.fulfilled, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMailboxList, [orgId, 'status'], FETCHING_STATUS.COMPLETED);
      })
      // .addCase(putReadById.fulfilled, (state, { meta }) => {
      //   const { orgId, from } = meta.arg;
      //   const current = _.get(state.orgMailboxList, [orgId, 'list']) || {};
      //   const filledListStatus = setFulfilledEndListStatus(_.set(current, [from, 'unread'], false));
      //   _.set(state.orgMailboxList, [orgId], filledListStatus);
      // })
      .addCase(setEmailRender, (state, { payload }) => {
        const { mailId, render } = payload;
        _.set(state.emailRenderStatus, [mailId], render);
      })
      .addCase(setAigcResponse, (state, { payload }) => {
        if (payload.status === FETCHING_STATUS.INIT) {
          _.set(state.aigcResponse, [payload.mailId], payload.response);
        } else {
          _.set(state.aigcResponse, [payload.mailId], _.get(state.aigcResponse, [payload.mailId]) + payload.response);
        }
        state.generatingAigcResponse = payload.status !== FETCHING_STATUS.DONE;
      })
      .addCase(getUserMailList.pending, (state, { meta }) => {
        const { orgId, from } = meta.arg;
        _.set(state.orgUserMails, [orgId, from || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getUserMailList.rejected, (state, { meta }) => {
        const { orgId, from } = meta.arg;
        _.set(state.orgUserMails, [orgId, from || '', 'status'], FETCHING_STATUS.DONE);
      })
      .addCase(getUserMailList.fulfilled, (state, { meta, payload }) => {
        const { orgId, from } = meta.arg;
        if (payload) {
          const { data, pagination } = payload;
          // const hasMore = _.get(state.orgUserMails, [orgId, from || '', 'hasMore']) || undefined;
          let fulfilledStatus: ListArrayStatus<IMailbox>;
          // const current = _.get(state.orgUserMails, [orgId, from || '', 'list']) || [];
          // switch (mode) {
          //   case 'unshift':
          //     if (_.isEmpty(payload) || payload.length < pageSize) {
          //       fulfilledStatus = setFulfilledEndListArrayStatus([...payload, ...current]) as ListArrayStatus<IMail>;
          //     } else {
          //       fulfilledStatus = setFulfilledListArrayStatus([...payload, ...current]) as ListArrayStatus<IMail>;
          //     }
          //     break;
          //   case 'append':
          //     if (_.isEmpty(payload)) {
          //       if (current.length < pageSize) {
          //         fulfilledStatus = setFulfilledEndListArrayStatus(current);
          //         fulfilledStatus.hasMore = hasMore;
          //       } else {
          //         fulfilledStatus = setFulfilledNoNewListArrayStatus(current) as ListArrayStatus<IMail>;
          //         fulfilledStatus.hasMore = hasMore;
          //       }
          //     } else {
          //       fulfilledStatus = setFulfilledListArrayStatus([...current, ...payload]) as ListArrayStatus<IMail>;
          //       fulfilledStatus.hasMore = hasMore;
          //     }
          //     break;
          //   default:
          if (pagination.page * pagination.pageSize > pagination.total) {
            fulfilledStatus = setFulfilledEndListArrayStatus(data, pagination);
            fulfilledStatus.status = FETCHING_STATUS.BOTTOM;
            fulfilledStatus.hasMore = false;
          } else {
            fulfilledStatus = setFulfilledBeginListArrayStatus(data, pagination);
            fulfilledStatus.hasMore = true;
          }
          // break;
          // }
          _.set(state.orgUserMails, [orgId, from || ''], fulfilledStatus);
        } else {
          _.set(state.orgUserMails, [orgId, from || '', 'status'], FETCHING_STATUS.DONE);
        }
      })
      .addCase(getAigcResponse.pending, (state, {}) => {
        state.generatingAigcResponse = true;
      })
      .addCase(getAigcResponse.fulfilled, (state, { payload, meta }) => {
        state.generatingAigcResponse = false;
        if (payload.errno !== 0) {
          toast.warning(payload.errmsg);
        } else {
          _.set(state.aigcResponse, [meta.arg.mailId], payload.data?.text || '');
        }
      })
      .addCase(getAigcResponse.rejected, (state, {}) => {
        state.generatingAigcResponse = false;
      })
      .addCase(setAigcCommentReplies, (state, { payload }) => {
        state.commentAigcReplies = _.merge({}, state.commentAigcReplies, payload);
      })
      .addCase(setAigcCommentReply, (state, { payload }) => {
        state.commentAigcReplies = _.set(state.commentAigcReplies, [payload.commentId], payload.reply);
      })
      .addCase(sendMail.pending, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendMail.fulfilled, (state, { meta, payload }) => {
        const { orgId, to } = meta.arg;
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          toast.success(`Mail sent to ${to}`);
          if (orgId) {
            _.set(state.orgMailboxList, [orgId, 'list', to, 'latestReplyDate'], new Date().toISOString());
            _.set(state.orgUserSentEmail, [orgId, to], Date.now());
          }
        }
        _.set(state.sendingMail, [meta.arg.to], false);
      })
      .addCase(sendMail.rejected, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendDirectMail.pending, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(sendDirectMail.fulfilled, (state, { meta, payload }) => {
        const { orgId, to } = meta.arg;
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          toast.success(`Mail sent to ${to}`);
          if (orgId) {
            _.set(state.orgMailboxList, [orgId, 'list', to, 'latestReplyDate'], new Date().toISOString());
            _.set(state.orgUserSentEmail, [orgId, to], Date.now());
          }
        }
        _.set(state.sendingMail, [meta.arg.to], false);
      })
      .addCase(sendDirectMail.rejected, (state, { meta }) => {
        _.set(state.sendingMail, [meta.arg.to], true);
      })
      .addCase(setInboxSourceType, (state, { payload }) => {
        const { orgId, key } = payload;
        _.set(state.orgInboxSourceType, [orgId], key);
      })
      .addCase(setMailboxFilter, (state, { payload }) => {
        const { orgId, filter } = payload;
        _.set(state.orgInboxMailboxFilter, [orgId], _.merge({}, _.get(state.orgInboxMailboxFilter, [orgId]), filter));
      })
      .addCase(setInboxDrawerOpen, (state, { payload }) => {
        const { drawerOpen } = payload;
        state.drawerOpen = drawerOpen;
      })
      .addCase(setInboxReplying, (state, { payload }) => {
        const { replying } = payload;
        state.replying = replying;
      })
      .addCase(setInboxCurrentId, (state, { payload }) => {
        const { orgId, id } = payload;
        _.set(state.orgCurrentMailboxId, [orgId], id);
      })
      .addCase(setInboxCurrentText, (state, { payload }) => {
        const { orgId, id, text } = payload;
        _.set(state.orgUserCurrentText, [orgId], { [id]: text });
      })
      .addCase(resetMeta, (state) => {
        state.orgMetaPosts = {};
        state.orgMetaPostComments = {};
        state.orgMetaPostCommentReplies = {};
      })
      .addCase(getMetaPosts.pending, (state, { meta }) => {
        const { orgId } = meta.arg;
        _.set(state.orgMetaPosts, [orgId, 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPosts.fulfilled, (state, { meta, payload }) => {
        const { orgId, pageSize = 10 } = meta.arg;
        let filledListStatus;

        if (_.values(payload).length < pageSize) {
          filledListStatus = setFulfilledEndListStatus(payload);
        } else {
          filledListStatus = setFulfilledListStatus(payload);
        }

        _.set(state.orgMetaPosts, [orgId], filledListStatus);
      })
      .addCase(getMetaPostComments.pending, (state, { meta }) => {
        const { orgId, metaPagePostId } = meta.arg;
        _.set(state.orgMetaPostComments, [orgId || '', metaPagePostId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPostComments.fulfilled, (state, { meta, payload }) => {
        const { orgId, metaPagePostId } = meta.arg;
        if (payload) {
          _.set(state.orgMetaPostComments, [orgId || '', metaPagePostId || ''], setFulfilledListStatus(payload));
        }
      })
      .addCase(setInboxPostSearchStr, (state, { payload }) => {
        const { orgId, searchStr } = payload;
        _.set(state.orgInboxPostSearchStr, [orgId], searchStr);
      })
      .addCase(setInboxCurrentPostId, (state, { payload }) => {
        const { orgId, id } = payload;
        _.set(state.orgCurrentPostId, [orgId], id);
      })
      .addCase(setInboxCommentSearchStr, (state, { payload }) => {
        const { orgId, searchStr } = payload;
        _.set(state.orgInboxCommentSearchStr, [orgId], searchStr);
      })
      .addCase(getMetaPostCommentReplies.pending, (state, { meta }) => {
        const { orgId, metaPostCommentId: commentId } = meta.arg;
        _.set(state.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'status'], FETCHING_STATUS.FETCHING);
      })
      .addCase(getMetaPostCommentReplies.fulfilled, (state, { meta, payload }) => {
        const { orgId, metaPostCommentId: commentId } = meta.arg;
        if (payload) {
          _.set(state.orgMetaPostCommentReplies, [orgId || '', commentId || ''], setFulfilledListStatus(payload));
        }
      })
      .addCase(setInboxCurrentPostComment, (state, { payload }) => {
        const { orgId, comment } = payload;
        _.set(state.orgCurrentPostComment, [orgId], comment);
      })
      .addCase(ReplyMetaPostComment.pending, (state, { meta }) => {
        _.set(state.replyingComment, [meta.arg.id || ''], true);
      })
      .addCase(ReplyMetaPostComment.fulfilled, (state, { meta, payload }) => {
        const { orgId, id, commentId } = meta.arg;
        const current = _.get(state.orgMetaPostCommentReplies, [orgId || '', id || '', 'list']) || {};
        if (payload.errno === ERROR_CODE.ERROR) {
          toast.error(payload.errmsg);
        } else if (payload) {
          _.set(
            state.orgMetaPostCommentReplies,
            [orgId || '', id || ''],
            setFulfilledListStatus({ ...current, ..._.keyBy([payload.data], 'id') }),
          );
          _.set(state.commentAigcReplies, [commentId || ''], '');
        }
        _.set(state.replyingComment, [meta.arg.id || ''], false);
      })
      .addCase(ReplyMetaPostComment.rejected, (state, { meta }) => {
        _.set(state.replyingComment, [meta.arg.id || ''], false);
      })
      .addCase(setAigcReplyResponse, (state, { payload }) => {
        if (payload.status === FETCHING_STATUS.INIT) {
          _.set(state.aigcReplyResponse, [payload.commentId], payload.response);
        } else {
          _.set(
            state.aigcReplyResponse,
            [payload.commentId],
            _.get(state.aigcReplyResponse, [payload.commentId]) + payload.response,
          );
        }
        state.gettingAigcReplyResponse = payload.status !== FETCHING_STATUS.DONE;
      })
      .addCase(setInboxMetaCurrentText, (state, { payload }) => {
        const { orgId, commentId, text } = payload;
        _.set(state.orgPostCurrentText, [orgId], { [commentId]: text });
      })
      .addCase(setPostCommentSelected, (state, { payload }) => {
        const currentComments = _.get(state.postSelectedComments, [payload.postId], []);
        _.set(
          state.postSelectedComments,
          [payload.postId],
          payload.selected
            ? [...currentComments, payload.commentId]
            : currentComments.filter((c) => c !== payload.commentId),
        );
      });
  },
});

export const selectMailboxList =
  (orgId: string | undefined) =>
  (state: AppState): IMailbox[] => {
    return _.get(state.inbox.orgMailboxList, [orgId || '', 'list']);
  };
export const selectMailboxListPagination =
  (orgId: string | undefined) =>
  (state: AppState): IPagination | undefined => {
    return _.get(state.inbox.orgMailboxList, [orgId || '', 'pagination']);
  };

export const selectMailboxById =
  (orgId: string | undefined, id: string) =>
  (state: AppState): IMailbox | undefined =>
    _.find(_.get(state.inbox.orgMailboxList, [orgId || '', 'list']), (m) => m.id === id);

export const selectMailboxListStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMailboxList, [orgId || '', 'status']);

export const selectUserMailList =
  (orgId: string | undefined, user: string | undefined) =>
  (state: AppState): IMailbox[] =>
    _.get(state.inbox.orgUserMails, [orgId || '', user || '', 'list']);
export const selectUserMailListPagination =
  (orgId: string | undefined, user: string | undefined) =>
  (state: AppState): IPagination | undefined =>
    _.get(state.inbox.orgUserMails, [orgId || '', user || '', 'pagination']);

export const selectCurrentUserId =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgCurrentMailboxId, [orgId || '']);

export const selectOrgInboxSourceType =
  (orgId: string | undefined) =>
  (state: AppState): INBOX_SOURCES_KEY =>
    _.get(state.inbox.orgInboxSourceType, [orgId || ''], INBOX_SOURCES_KEY.GMAIL);

export const selectOrgMailboxFilter =
  (orgId: string | undefined) =>
  (state: AppState): IMailBoxFilter =>
    _.get(state.inbox.orgInboxMailboxFilter, [orgId || '']);

export const selectOrgInboxUserText =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgUserCurrentText, [orgId || '', id || '']);

export const selectEmailListStatus =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgUserMails, [orgId || '', id || '', 'status']);

export const selectEmailListHasMore =
  (orgId: string | undefined, id: string | undefined) =>
  (state: AppState): boolean | undefined =>
    _.get(state.inbox.orgUserMails, [orgId || '', id || '', 'hasMore']);

export const selectSendingMail =
  (address: string | undefined) =>
  (state: AppState): boolean =>
    _.get(state.inbox.sendingMail, [address || '']);
export const selectGeneratingAigcResponse = (state: AppState): boolean => state.inbox.generatingAigcResponse;
export const selectAigcResponse =
  (mailId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.aigcResponse, [mailId || ''], '');
export const selectEmailRenderStatus = (state: AppState): { [mailId: string]: string } => state.inbox.emailRenderStatus;
export const selectUserSentEmail =
  (orgId: string | undefined, userId: string) =>
  (state: AppState): number =>
    _.get(state.inbox.orgUserSentEmail, [orgId || '', userId]);
export const selectInboxDrawerOpen = (state: AppState): boolean => state.inbox.drawerOpen;
export const selectInboxReplying = (state: AppState): boolean => state.inbox.replying;
export const selectMetaPosts =
  (orgId: string | undefined) =>
  (state: AppState): Record<string, IMetaPost> =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'list']);

export const selectMetaPostsStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'status']);

export const selectMetaPostsComments =
  (orgId: string | undefined, postId: string | undefined) =>
  (state: AppState): Record<string, IMetaComment> =>
    _.get(state.inbox.orgMetaPostComments, [orgId || '', postId || '', 'list']);

export const selectMetaPostsCommentsStatus =
  (orgId: string | undefined, postId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPostComments, [orgId || '', postId || '', 'status']);

export const selectOrgInboxPostSearchStr =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgInboxPostSearchStr, [orgId || '']);

export const selectCurrentPostId =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgCurrentPostId, [orgId || '']);

export const selectOrgInboxCommentSearchStr =
  (orgId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgInboxCommentSearchStr, [orgId || '']);

export const selectMetaPostCommentReplies =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): Record<string, IMetaComment> =>
    _.get(state.inbox.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'list']);

export const selectMetaPostCommentRepliesStatus =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMetaPostCommentReplies, [orgId || '', commentId || '', 'status']);

export const selectCurrentPostComment =
  (orgId: string | undefined) =>
  (state: AppState): IMetaComment =>
    _.get(state.inbox.orgCurrentPostComment, [orgId || '']);

export const selectMetaPostById =
  (orgId: string | undefined, id: string) =>
  (state: AppState): IMetaPost | undefined =>
    _.get(state.inbox.orgMetaPosts, [orgId || '', 'list', id]);

export const selectReplyingComment =
  (id: string | undefined) =>
  (state: AppState): boolean =>
    _.get(state.inbox.replyingComment, [id || ''], false);
export const selectReplyingComments = (state: AppState): boolean =>
  _.some(_.values(state.inbox.replyingComment), (replying) => !!replying);

export const selectAigcReplyResponse =
  (commentId?: string) =>
  (state: AppState): string =>
    _.get(state.inbox.aigcReplyResponse, [commentId || ''], '');

export const selectAigcCommentReply =
  (commentId?: string) =>
  (state: AppState): string =>
    _.get(state.inbox.commentAigcReplies, [commentId || ''], '');
export const selectPostCommentsSelected =
  (postId?: string) =>
  (state: AppState): string[] =>
    _.get(state.inbox.postSelectedComments, [postId || '']);

export const selectOrgInboxMetaCurrentText =
  (orgId: string | undefined, commentId: string | undefined) =>
  (state: AppState): string =>
    _.get(state.inbox.orgPostCurrentText, [orgId || '', commentId || '']);

export const selectGettingAigcReplyResponse = (state: AppState): boolean => state.inbox.gettingAigcReplyResponse;

export const selectMailUserList =
  (orgId: string | undefined) =>
  (state: AppState): IMailboxUser[] => {
    return _.get(state.inbox.orgMailUserList, [orgId || '', 'list']);
  };

export const selectMailUserListPagination =
  (orgId: string | undefined) =>
  (state: AppState): IPagination | undefined => {
    return _.get(state.inbox.orgMailUserList, [orgId || '', 'pagination']);
  };

export const selectMailUserListStatus =
  (orgId: string | undefined) =>
  (state: AppState): FETCHING_STATUS =>
    _.get(state.inbox.orgMailUserList, [orgId || '', 'status']);

export default inboxSlice.reducer;
