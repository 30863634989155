/**
 * @description Component-ChatReplyBox
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Input, Switch } from 'antd';
import { ReactComponent as RobotOutlined } from '@/assets/svg/icon_xing.svg';
import { ReactComponent as SendOutlined } from '@/assets/svg/icon_btnsend.svg';
import { ReactComponent as AddIcon } from '@/assets/svg/icon_add_16.svg';
import { useAppDispatch } from '@/features/hooks';
import { useGeneratingAigcResponse } from '@/features/inbox/hooks';

import './index.less';
import { useSocket } from '@/features/socket/hooks';
import { SOCKET_CHANNELS } from '@/constants/sockets';
import { IPluginSession } from '@/types/plugin';
import { SESSION_MODE } from '@/constants/plugin';
import EditTicket from '@/pages/Tickets/EditTicket';

interface IPropTypes {
  className?: string;
  session: IPluginSession | undefined;
  lastCustomerQuestion: string | undefined;
}

const PluginReplyBox: FC<IPropTypes> = function ({ session, lastCustomerQuestion }) {
  const dispatch = useAppDispatch();
  // const gettingAigcResponse = useGettingAigcResponse();
  const [, setIsCommandPressed] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const socket = useSocket(SOCKET_CHANNELS.LIVE_CHAT_MANUAL);
  const [currentText, setCurrentText] = useState('');
  const [replying, setReplying] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [manual, setManual] = useState(false);
  const inputMessage = (messageText: string) => {
    setCurrentText(messageText);
  };

  const replyToCustomer = useCallback(() => {
    if (session) {
      setReplying(true);
      socket?.emit('replyToCustomer', {
        content: currentText,
        sessionId: session?.conversationId,
        id: session?.id,
      });
      setReplying(false);
      setCurrentText('');
    }
  }, [currentText, session, socket]);

  // const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Meta' || e.key === 'Control') {
      setIsCommandPressed(true);
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (currentText) {
        replyToCustomer();
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Meta' || e.key === 'Control') {
      setIsCommandPressed(false);
    }
  };

  const scrollToBottom = useCallback(() => {
    containerRef.current &&
      containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
  }, []);
  const generateResponse = useCallback(() => {
    if (socket && session && lastCustomerQuestion) {
      if (!socket.connected) {
        socket.connect();
      }
      socket.emit('message', {
        sessionId: session.conversationId,
        question: lastCustomerQuestion,
      });
      setCurrentText('');
      setGenerating(true);
    }
  }, [lastCustomerQuestion, session, socket]);
  const onResponse = useCallback(
    (data: IPluginSession) => {
      setGenerating(false);
      data && setCurrentText((curr) => curr + (data?.content || ''));
      scrollToBottom();
      textareaRef.current?.focus();
    },
    [scrollToBottom],
  );

  useEffect(() => {
    if (socket) {
      socket.on('response', onResponse);
    }
    return () => {
      socket && socket.off('response', onResponse);
    };
  }, [dispatch, onResponse, socket]);

  useEffect(() => {
    setManual(session?.mode === SESSION_MODE.MANUAL);
  }, [session?.mode]);
  return (
    <div className="cor-plugin-reply-box">
      <div className="cor-plugin-reply-box-input-container">
        <div className="header">
          <div className="switch-box">
            <Switch
              checked={manual}
              onChange={(manual) => {
                setManual(manual);
                socket?.emit('switchMode', {
                  switchMode: manual ? SESSION_MODE.MANUAL : SESSION_MODE.AUTO,
                  sessionId: session?.conversationId,
                });
              }}></Switch>
            Manual Take Over
          </div>
          <div className="btns">
            <EditTicket
              initialValues={{ customerEmail: session?.email, type: 'manual', source: 'chat' }}
              text="Create Ticket"
              title="Create Ticket"
              type="ghost"
              icon={<AddIcon />}
            />
            {manual && (
              <>
                {' '}
                <Button type="ghost" disabled={generating} onClick={generateResponse} loading={generating}>
                  <AddIcon /> Generate Response
                </Button>
              </>
            )}
          </div>
        </div>
        {manual && (
          <>
            <div ref={containerRef}>
              <Input.TextArea
                ref={textareaRef}
                disabled={generating}
                onChange={(e) => inputMessage(e.target.value)}
                value={currentText}
                autoSize={{ minRows: 4, maxRows: 4 }}
                onKeyUp={(e) => handleKeyUp(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                rows={1}
                placeholder="Reply with a manual response here"
                bordered
              />
            </div>
            <Button
              type="primary"
              className="send"
              onClick={replyToCustomer}
              disabled={!currentText || replying}
              loading={replying}>
              Send
              {replying ? <></> : <SendOutlined />}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default PluginReplyBox;
