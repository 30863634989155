import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Typography, message, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './index.less';
import { useAppDispatch } from '@/features/hooks';
import { addDataAnalysisCategory, updateDataAnalysisCategory ,getDataAnalysisCategory} from '@/features/dataAnalysis/actions';
const { Text } = Typography;

interface IPropTypes {
  onStartSorting: () => void;
}

interface Category {
  category: string;
  color: string;
  id?: string; 
}

const CategorySetup: React.FC<IPropTypes> = ({ onStartSorting }) => {
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<Category[]>([
    { category: '', color: '' },
    { category: '', color: '' },
    { category: '', color: '' },
  ]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getDataAnalysisCategory({ category: '' })); // Pass the required parameter
      if (response && response.payload) {
        console.log(response);
        setCategories(response.payload);
      }
    };

    fetchData();
  }, [dispatch]);
  const handleCategoryChange = (index: number, key: 'category' | 'color', value: string) => {
    const newCategories = [...categories];
    newCategories[index][key] = value;
    setCategories(newCategories);
  };

  const addCategory = () => {
    setCategories([...categories, { category: '', color: '' }]);
  };

  const startSorting = async () => {
    if (categories.some((cat) => !cat.category || !cat.color)) {
      message.error('Please fill in all category names and choose colors.');
      return;
    }
    setLoading(true);
    // 循环检查每个类别是否有 id 字段
    for (const cat of categories) {
      if (!cat.id) {
        await dispatch(addDataAnalysisCategory(cat));
      }
    }
    setLoading(false);
    onStartSorting();
  };


  // 20个常用颜色
  const availableColors = [
    '#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33FF', '#33FFFF',
    '#FF7F50', '#800080', '#008080', '#FFA500', '#FFC0CB', '#A52A2A',
    '#F0E68C', '#ADFF2F', '#D2691E', '#C71585', '#90EE90', '#87CEEB',
    '#FFD700', '#4B0082'
  ];


  const customLabel = (color: string) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: color,
          borderRadius: '50%',
        }}
      />
      <span>{color}</span>
    </div>
  );

  return (
    <div className="cor-category-setup-container">
      <Typography.Title level={4} className="category-setup-title">
        Sorting Data for your Report
      </Typography.Title>
      <Text className="category-setup-description">
        Before you start sorting, please list at least <strong>3 categories</strong> you would like to classify your
        emails as. You can add, edit, or delete categories under the Settings tab in the future.
      </Text>

      <Space direction="vertical" className="category-setup-space">
        <div style={{overflow:"auto",maxHeight:"calc(100vh - 380px)"}}>
        {categories.map((category, index) => (
          <Space key={index} className="category-row">
            <Input
              placeholder={`Category ${index + 1}`}
              value={category.category}
              onChange={(e) => handleCategoryChange(index, 'category', e.target.value)}
              className="category-input"
            />
            <Select
              value={category.color || undefined}
              onChange={(color) => handleCategoryChange(index, 'color', color)}
              placeholder="Choose Color"
              style={{ width: 150 }}
              optionLabelProp="label"
            >
              {/* Filter out the selected colors */}
              {availableColors
                .filter((color) => !categories.some((cat) => cat.color === color && cat.color !== category.color))
                .map((color) => (
                  <Select.Option key={color} value={color} label={customLabel(color)}>
                    {customLabel(color)}
                  </Select.Option>
                ))}
            </Select>
          </Space>
        ))}
        </div>
  
        <Button icon={<PlusOutlined />} onClick={addCategory} className="add-button">
          Add Category
        </Button>

        <Button onClick={startSorting} loading={loading} disabled={categories.length < 3} className="start-button">
          Start sorting
        </Button>
      </Space>
    </div>
  );
};

export default CategorySetup


