/**
 * @description Component-search
 */
import React, { FC } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.less';

interface IPropTypes {
  className?: string;
  onCategorySubmit: () => void; 
}

const Settings: FC<IPropTypes> = function ({onCategorySubmit }) {


  const handleSearch = (value: string) => {
    onCategorySubmit(value)
  };

  return (
    <div className="cor-settings-search">
      <Input
        placeholder="Search Data..."
        prefix={<SearchOutlined className="search-icon" />}
        allowClear
        className="search-input"
        onPressEnter={(e) => handleSearch((e.target as HTMLInputElement).value)}
      />
    </div>
  );
};

export default Settings;
