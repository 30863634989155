import { CategoryItem } from '@/types/dataAnalysis';
import { IResponse } from '@/types/settings';
import { IMail } from '@/types/email';
import { axiosGet, axiosPost, axiosPut, axiosDelete } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const getDataAnalysisCategory = createAsyncThunk(
  'dataAnalysis/getDataAnalysisCategory',
  async ({
    category
  }: {
    category?:string
  }): Promise<IResponse<CategoryItem[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisCategory`, {
        params: {
          category
        },
      });
      return result.data.data;
    } catch (e) {
      throw e;
    }
  },
);
export const addDataAnalysisCategory = createAsyncThunk(
  'dataAnalysis/addDataAnalysisCategory',
  async (
    {
      category,
      color,
    }: {
      category:string;
      color:string;
    }
  ): Promise<IResponse<CategoryItem[]>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisCategory`, {
        category,
        color,
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);

export const updateDataAnalysisCategory = createAsyncThunk(
  'dataAnalysis/updateDataAnalysisCategory',
  async (
    {
      category,
      category_id,
      color,
    }: {
      category:string;
      category_id?: string;
      color:string;
    }
  ): Promise<IResponse<CategoryItem[]>> => {
    try {
      const result = await axiosPut(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisCategory`, {
        category,
        category_id,
        color,
      });
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const deleteDataAnalysisCategory = createAsyncThunk(
  'dataAnalysis/deleteDataAnalysisCategory',
  async (
    {
      category_id,
    }: {
      category_id: string;
    }
  ): Promise<IResponse<CategoryItem[]>> => {
    try {
      const result = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisCategory`,{category_id});
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getDataAnalysisMailbox = createAsyncThunk(
  'dataAnalysis/getDataAnalysisMailbox',
  async ({
    page,
    pagesize,
    category,
    subject,
  }: {
    page:number,
    pagesize:number,
    category?:string,
    subject?:string,
  }): Promise<IResponse<IMail[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisMailbox`, {
        params: {
          page,
          pagesize,
          category,
          subject,
        },
      });
      return result.data.data;
    } catch (e) {
      throw e;
    }
  },
);
export const getDataAnalysisMailboxDetail = createAsyncThunk(
  'dataAnalysis/getDataAnalysisMailboxDetail',
  async ({ mailbox_id}: { mailbox_id: string}): Promise<IResponse<IMail[]>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisMailbox/${mailbox_id}`);

      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const updateDataAnalysisMailbox = createAsyncThunk(
  'dataAnalysis/updateDataAnalysisMailbox',
  async ({ mailbox_id,category}: { mailbox_id: string,category:string }): Promise<IResponse<IMail[]>> => {
    try {
      const result = await axiosPost(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisMailbox/${mailbox_id}`,{category});

      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const deleteDataAnalysisMailbox = createAsyncThunk(
  'dataAnalysis/deleteDataAnalysisMailbox',
  async ({ mailbox_id}: { mailbox_id: string }): Promise<IResponse<IMail[]>> => {
    try {
      const result = await axiosDelete(`${process.env.REACT_APP_API_ROOT_V2}dataAnalysisMailbox/${mailbox_id}`);

      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
export const checkReportConditions = createAsyncThunk(
  'dataAnalysis/checkReportConditions',
  async (): Promise<IResponse<boolean>> => {
    try {
      const result = await axiosGet(`${process.env.REACT_APP_API_ROOT_V2}checkReportConditions`);
      return result.data;
    } catch (e) {
      throw e;
    }
  },
);
