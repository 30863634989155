import React, { FC, useMemo, useEffect, useState } from 'react';
import './index.less';

import { Table, ConfigProvider, Button, Typography, Progress } from 'antd';
import { DataAnalysis } from '@/types/plugin';
import { ColumnType, TableProps } from 'antd/lib/table';
import DeleteData from './DeleteData';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/features/hooks';
import Search from './search';
import { checkReportConditions } from '@/features/dataAnalysis/actions';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

const rowClassName = () => 'custom-table-row';

const Report: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [reportConditions, setReportConditions] = useState<any[]>([]);
  const [canGenerateReport, setCanGenerateReport] = useState(false);
  const data: DataAnalysis[] = [];

  useEffect(() => {
    dispatch(checkReportConditions())
      .then((response) => {
        const { can_generate_report, report_conditions } = response.payload.data;
        setCanGenerateReport(can_generate_report);
        setReportConditions(report_conditions);
        console.log('Report conditions checked:', response);
      })
      .catch((error) => {
        console.error('Error checking report conditions:', error);
      });
  }, [dispatch]);

  const columDefs: ColumnType<DataAnalysis>[] = useMemo(
    () => [
      {
        title: 'Date Generated',
        align: 'left',
        ellipsis: true,
        sorter: true,
        render: (_value, record) => record.title,
      },
      {
        title: 'Report',
        align: 'left',
        ellipsis: true,
        render: (_value, record) => record.title,
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value, record) => (
          <div className="cor-data-actions">
            <DeleteData id={record.id} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [],
  );

  const pagination = useMemo(
    () => ({
      pageSize: 10,
      total: data.length,
      showTotal: (total: number) => `Total data ${total}`,
      showSizeChanger: true,
    }),
    [data.length],
  );

  const onChange: TableProps<DataAnalysis>['onChange'] = (pagination, _filters, sorter, _extra) => {
    console.log(pagination, _filters, sorter, _extra);
  };

  const tableEmptyRender = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {!canGenerateReport && (
          <div>
            <Typography.Title level={4}>Not Enough Data to Generate Report</Typography.Title>
            <Typography.Text style={{ color: '#33333399' }}>
              You must categorize 5 emails per category for an accurate report.
            </Typography.Text>
            <Button
              type="primary"
              style={{ margin: '20px auto' }}
              onClick={() => navigate('/dashboard/data-analysis/sort')}>
              Sort more data
            </Button>
          </div>
        )}

        <div style={{ maxWidth: '700px', margin: '40px auto' }}>
          {reportConditions.map(({ category, color, email_count, progress }) => (
            <div key={category} style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <Typography.Text style={{ flex: 1 }}>{category}</Typography.Text>
              <Progress
                percent={progress}
                strokeColor={color}
                trailColor={`${color}33`}
                strokeWidth={20}
                style={{ width: '60%', marginRight: '8px' }}
              />
              <Typography.Text>{`${email_count} /5 sorted`}</Typography.Text>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="cor-report">
      {canGenerateReport ? (
        <>
          <div className="cor-reoprt-Generate" style={{ textAlign: 'center', padding: '20px' }}>
            <Typography.Title level={4}>Generate Report?</Typography.Title>
            <Typography.Text style={{ color: '#33333399' }}>Your generated report will show up below.</Typography.Text>
            <Button
              type="primary"
              style={{ margin: '20px auto' }}
              onClick={() => navigate('/dashboard/data-analysis/sort')}>
              Generate Report
            </Button>
          </div>
          <div className="cor-report-head">
            <Search />
          </div>
        </>
      ) : (
        <div />
      )}
      <ConfigProvider renderEmpty={tableEmptyRender}>
        <Table
          rowKey={(record) => record?.id}
          columns={columDefs}
          dataSource={data}
          pagination={pagination}
          className="cor-report-table"
          rowClassName={rowClassName}
          onChange={onChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default Report;
