/**
 * @description Component-DeleteData
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_table_delete.svg';

import { Button } from 'antd';
import { deleteTicket } from '@/features/plugin/actions';
import { useTicketDeletingStatus } from '@/features/plugin/hooks';
import { FETCHING_STATUS } from '@/constants';
import { useNavigate } from 'react-router-dom';
interface IPropTypes {
  className?: string;
  id: string;
}
const DeleteData: FC<IPropTypes> = function ({ id }) {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const deleting = useTicketDeletingStatus(id);
  const navigate = useNavigate();
  const delMember = useCallback(async () => {
    if (org) {
      const result = await dispatch(deleteTicket({ orgId: org?.id, id })).unwrap();
      if (result) {
        if (result) {
          navigate('/dashboard/tickets');
        }
      }
    }
  }, [dispatch, org, id, navigate]);
  return (
    <Button
      loading={deleting === FETCHING_STATUS.FETCHING}
      onClick={delMember}
      icon={<DeleteIcon />}
      className="cor-delete-ticket-button"></Button>
  );
};

export default DeleteData;
