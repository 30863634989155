/**
 * @description Component-DeleteData
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_table_delete.svg';
import { Button, Modal } from 'antd';
import { deleteDataAnalysisCategory } from '@/features/dataAnalysis/actions';
interface IPropTypes {
  className?: string;
  id: string;
  onCategorySubmit: () => void; 
}
const DeleteData: FC<IPropTypes> = function ({ id,onCategorySubmit}) {
  const org = useOrg();
  const dispatch = useAppDispatch();

  const delMember = useCallback(() => {
    const handleDelete = async () => {
      await dispatch(deleteDataAnalysisCategory({ category_id: id }));
      onCategorySubmit(); // Executes after the dispatch action completes
    };
  
    Modal.confirm({
      title: 'Confirm Deletion',
      content: '"Are you sure you want to delete the current category?',
      onOk: handleDelete, // Pass the async function
      okText: 'Yes',
      cancelText: 'No',
      className: 'delete-confirm-modal',
    });
  }, [dispatch, id, onCategorySubmit]);
  
  return (
    <Button
      onClick={delMember}
      icon={<DeleteIcon />}
      className="cor-delete-ticket-button"></Button>
  );
};

export default DeleteData;
