export enum SOCKET_CHANNELS {
  //to reply mail
  MAIL = 'llm/mail/completions',
  //
  CHATBOT = 'chatbot',

  // for meta reply
  // META = 'meta', // no emit used
  // META_IN_ONE = 'meta/reply-in-one',

  // live chat
  LIVE_CHAT_MANUAL = 'livechat/manual',
  LIVE_CHAT_GENERATE_EMAIL = 'livechat/generate_mail',

  // for demo purpose
  LIVE_DEMO = 'livedemo',

  //knowledge
  KNOWLEDGE_PLAYGROUND = 'livechat/playground',
}
export enum SOCKET_COMMANDS {
  CONVERSATION = 'conversation',
  SEND_MAIL = 'send_mail',
  SEARCH = 'search',
}
export enum SOCKET_STATUS {
  FINISHED = 'finished',
  FETCHING = 'fetching',
}
