/**
 * @description Component-PostList
 */
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '@/features/hooks';
import { GetPostListRequest, getMetaPosts, setInboxCurrentPostId } from '@/features/inbox/actions';
import { useMetaPosts, useMetaPostsStatus, useInboxPostSearchStr, useCurrentPostId } from '@/features/inbox/hooks';
import { useOrg, useSocialSettings } from '@/features/settings/hooks';
import InfiniteScroller from 'react-infinite-scroller';
import { FETCHING_STATUS, SETTINGS_TABS } from '@/constants';
import { Empty } from 'antd';
import _ from 'lodash';

import './index.less';

import PostItem from './PostItem';
import PostListSkeleton from './PostListSkeleton';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

interface IPropTypes {
  className?: string;
}
const PostList: FC<IPropTypes> = function ({}) {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { deviceType } = useMediaQueryDevice();
  const query = useInboxPostSearchStr(org?.id);
  const posts = useMetaPosts(org?.id);
  const navigate = useNavigate();
  const postsStatus = useMetaPostsStatus(org?.id);
  const prevScrollHeight = useRef<number>(0);
  const wrapRef = useRef<HTMLDivElement>(null);
  const socialSettings = useSocialSettings();

  const currentId = useCurrentPostId(org?.id);
  useEffect(() => {
    if (
      (postsStatus === FETCHING_STATUS.DONE || postsStatus === FETCHING_STATUS.COMPLETED) &&
      wrapRef.current &&
      prevScrollHeight.current !== 0
    ) {
      wrapRef.current.scrollTop = wrapRef.current.scrollHeight - prevScrollHeight.current;
    }
  }, [postsStatus, prevScrollHeight, wrapRef]);

  const onLoad = useCallback(
    async (mode?: string | number) => {
      if (
        org &&
        (postsStatus === undefined ||
          postsStatus === FETCHING_STATUS.DONE ||
          mode === 'unshift' ||
          (query !== undefined && postsStatus < FETCHING_STATUS.FETCHING))
      ) {
        const params: GetPostListRequest = {
          orgId: org.id,
          search: query,
          mode: 'refresh',
        };
        if (mode === 'unshift') {
          params.mode = mode;
        }
        if (_.last(posts)?.id && (!mode || postsStatus === FETCHING_STATUS.DONE) && mode !== 'refresh') {
          params.mode = 'append';
          params.preId = _.last(posts)?.id;
        }
        dispatch(getMetaPosts(params));
      }
    },
    [org, posts, postsStatus, dispatch, query],
  );

  // polling
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (postsStatus !== FETCHING_STATUS.FETCHING) {
        console.log('🚀 ~ file: index.tsx:119 ~ polling ~ userStatus:', postsStatus);
        onLoad();
      }
    }, 60 * 1000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [onLoad, postsStatus]);

  // search
  useEffect(() => {
    if (query !== undefined) {
      onLoad('refresh');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // check currentPostId
  useEffect(() => {
    if (posts?.length) {
      if (!posts.filter((i) => i.id === currentId).length) {
        org && dispatch(setInboxCurrentPostId({ orgId: org?.id, id: '' }));
      }
    } else {
      org && dispatch(setInboxCurrentPostId({ orgId: org?.id, id: '' }));
    }
    if (posts?.length && !currentId) {
      org && dispatch(setInboxCurrentPostId({ orgId: org?.id, id: _.first(posts)?.id || '' }));
    }
  }, [org, posts, currentId, dispatch]);
  useEffect(() => {
    org && onLoad('unshift');
  }, [org?.id]);

  return (
    <div className={classNames('cor-post-list-wrap', deviceType)} ref={wrapRef}>
      <InfiniteScroller
        className="cor-post-list"
        initialLoad={false}
        useWindow={false}
        hasMore={postsStatus !== FETCHING_STATUS.COMPLETED}
        getScrollParent={() => wrapRef.current}
        loadMore={onLoad}>
        {(postsStatus === FETCHING_STATUS.FETCHING || postsStatus === undefined) && !posts?.length && (
          <PostListSkeleton />
        )}
        {!socialSettings.id && socialSettings.status === FETCHING_STATUS.DONE && (
          <Empty
            description={
              <>
                <div>No Meta Account connected!</div>
                <div>
                  Please set up Meta connection first!{' '}
                  <a onClick={() => navigate(`/dashboard/settings/${SETTINGS_TABS.CONNECTIONS}`)}>Settings</a>
                </div>
              </>
            }
          />
        )}
        {postsStatus !== FETCHING_STATUS.FETCHING &&
          !posts?.length &&
          !!socialSettings.id &&
          socialSettings.status === FETCHING_STATUS.DONE && (
            <Empty
              description={
                <>
                  <div>
                    Please come back in 1 minute or two. The initial synchronization with your account might take a bit
                    of time...
                  </div>
                </>
              }
            />
          )}
        {posts?.map((post) => (
          <PostItem key={post.id} post={post} />
        ))}
      </InfiniteScroller>
    </div>
  );
};

export default PostList;
