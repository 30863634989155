import React, { FC, useEffect, useState } from 'react';
import './index.less';
import CategoryStep from './categoryStep';
import EmailCategorization from './emailCategorization';

interface IPropTypes {
  className?: string;
}

const Sort: FC<IPropTypes> = function () {
  const [showEmailCategorization, setShowEmailCategorization] = useState(false);

  const handleStartSorting = () => {
    console.log('start sorting');
    setShowEmailCategorization(true);
  };

  return (
    <div className="cor-sort">
      {!showEmailCategorization && <CategoryStep onStartSorting={handleStartSorting} />}

      {showEmailCategorization && <EmailCategorization />}
    </div>
  );
};

export default Sort;
