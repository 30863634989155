import React, { FC, useMemo, useEffect, useState, useRef } from 'react';
import './index.less';
import { Table, ConfigProvider, Empty, Modal } from 'antd';
import { DataAnalysis } from '@/types/dataAnalysis';
import { ColumnType, TableProps } from 'antd/lib/table';

import { useAppDispatch } from '@/features/hooks';

import { ReactComponent as NoFound } from '@/assets/svg/icon_no_found.svg';
import CategorySelect from './CategorySelect';
import DeleteData from './DeleteData';
import Search from './search';
import { getDataAnalysisCategory,getDataAnalysisMailbox,updateDataAnalysisMailbox,deleteDataAnalysisMailbox } from '@/features/dataAnalysis/actions';
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
interface Category {
  category: string;
  color: string;
  id?: string; 
}
const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};
const tableEmptyRender = () => {
  return (
    <Empty
      className="cor-data-empty"
      image={<NoFound />}
      description={
        <>
          <div>No Data Sorted Yet</div>
          <div style={{ color: '#8C8C8C', fontSize: '12px' }}>Start sorting data to generate your report.</div>
        </>
      }
    />
  );
};

const Data: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [category, setCategory] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [data, setData] = useState<DataAnalysis[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  
  const pageRef = useRef(page);

  useEffect(() => {
    pageRef.current = page; 
  }, [page]);

  // Fetch data when component mounts or parameters change
  useEffect(() => {
    setLoading(true);
    dispatch(getDataAnalysisCategory({category:''}))
      .then(response => {
        setCategories(response.payload);
        setLoading(false);
      });
  }, [dispatch]); 
  useEffect(() => {
    setLoading(true);
    dispatch(getDataAnalysisMailbox({ page, pagesize: pageSize, category, subject }))
      .then(response => {
        setData(response.payload.mailboxes); 
        setTotal(response.payload.total_count);
        setLoading(false);
      });
  }, [dispatch, page, pageSize, category, subject]);

  const columDefs: ColumnType<DataAnalysis>[] = useMemo(
    () => [
      {
        title: 'Subject',
        key: 'subject',
        align: 'left',
        ellipsis: true,
        render: (_value, record) => record.subject,
      },
      {
        title: 'Customer Email',
        key: 'customereEmail',
        ellipsis: true,
        render: (_value, record) => record.to,
      },

      {
        title: 'Category',
        key: 'category',
        ellipsis: true,
        sorter: true,
        align: 'right',
        render: (_value, record) => {
          const category = record.aiCategory || '';
          return (
            <CategorySelect 
              category={category} 
              categories={categories}  
              onCategoryChange={(newCategory) => onlistCategoryChange(newCategory, record.id)} 
            />
          );
        },
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value, record) => (
          <div className="cor-data-actions">
            <DeleteData id={record.id} onDelete={onDelete} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [categories],
  );

  const pagination = useMemo(
    () => ({
      pageSize: 10,
      total,
      showTotal: (total: number) => `Total data ${total}`,
      showSizeChanger: true,
    }),
    [total],
  );

  const onChange: TableProps<DataAnalysis>['onChange'] = (pagination, _filters, sorter, _extra) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);

  };

  const rowClassName = () => 'custom-table-row';
  const dataStatus = false;

  const onCategoryChange = (newCategory: string) => {
    setCategory(newCategory);
  };
  const onlistCategoryChange = (newCategory: string, id: string) => {
    console.log('getDataAnalysisMailbox', pageRef.current);
    dispatch(updateDataAnalysisMailbox({ mailbox_id: id, category: newCategory }))
      .then(() => {
        dispatch(getDataAnalysisMailbox({ page: pageRef.current, pagesize: pageSize, category, subject }))
          .then(response => {
            setData(response.payload.mailboxes);
          });
      });
  };
  const onDelete = (id: string) => {
    const handleDelete = async () => {
      dispatch(deleteDataAnalysisMailbox({ mailbox_id: id }))
        .then(() => {
          dispatch(getDataAnalysisMailbox({ page: pageRef.current, pagesize: pageSize, category, subject }))
            .then(response => {
              setData(response.payload.mailboxes);
            });
        });
    };
    
    Modal.confirm({
      title: 'Confirm Deletion',
      content: '"Are you sure you want to delete the current email?',
      onOk: handleDelete, 
      okText: 'Yes',
      cancelText: 'No',
      className: 'delete-confirm-modal',
    });
  };
  const onSearch = (value: string) => {
    setSubject(value);
  };
  return (
    <div className="cor-data">
      <div className="cor-data-head">
        <Search onSearch={onSearch}/>
        <div style={{ display: 'flex', alignItems: 'center' ,gap:"20px",color:"#8C8C8C"}}>
          <div>Sort by</div>
          <CategorySelect category={category} categories={categories} onCategoryChange={onCategoryChange}/>
        </div>
  
      </div>
      <ConfigProvider renderEmpty={tableEmptyRender}>
        <Table
          loading={loading}
          rowKey={(record) => record?.id + record.updatedAt}
          columns={columDefs}
          dataSource={data}
          pagination={pagination}
          className="cor-data-table"
          rowClassName={rowClassName}
          onChange={onChange}
          style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
          // onRow={(record) => ({
          //   onClick: () => {
          //     navigate(`/dashboard/data/${record.id}`);
          //   },
          // })}
        />
      </ConfigProvider>
    </div>
  );
};

export default Data;
