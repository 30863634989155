/**
 * @description Component-ChatContent
 */
import { Button, Empty } from 'antd';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { filter } from 'lodash';
import { FETCHING_STATUS } from '@/constants';
import { useOrg } from '@/features/settings/hooks';
import {
  useCurrentMailboxId,
  useMailListHasMore,
  useMailListStatus,
  useMailboxById,
  useUserMailList,
  useUserMailListPagination,
} from '@/features/inbox/hooks';
import { useAppDispatch } from '@/features/hooks';
import { getUserMailList, putReadById } from '@/features/inbox/actions';
import InfiniteScroller from 'react-infinite-scroller';

import ChatContentItem from './ChatContentItem';
import './index.less';

interface IPropTypes {
  className?: string;
}

const ChatContent: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const currentId = useCurrentMailboxId(org?.id);
  const pagination = useUserMailListPagination(org?.id, currentId);
  const user = useMailboxById(org?.id, currentId);
  const mails = useUserMailList(org?.id, user?.fromAddr);
  console.log('🚀 ~ mails:', mails);
  const [prevScrollY, setPrevScrollY] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<string>('up');
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const prevScrollHeight = useRef<number>(0);
  const mailListStatus = useMailListStatus(org?.id, currentId);
  const mailListHasMore = useMailListHasMore(org?.id, currentId);
  const onLoadMore = useCallback(() => {
    if (mailListStatus === FETCHING_STATUS.FETCHING) {
      return;
    }
    if (org) {
      if (scrollContainerRef.current) {
        prevScrollHeight.current = scrollContainerRef.current.scrollHeight;
      }
      dispatch(
        getUserMailList({ orgId: org.id, from: user?.fromAddr, page: (pagination?.page || 0) + 1, to: user?.fromAddr }),
      );
    }
  }, [mailListStatus, org, dispatch, user?.fromAddr, pagination?.page]);

  const onListScroll = useCallback(() => {
    if (mailListStatus === FETCHING_STATUS.FETCHING || scrollDirection === 'up') {
      return;
    }
    if (org && user?.fromAddr) {
      dispatch(
        getUserMailList({ orgId: org.id, from: user?.fromAddr, to: user.fromAddr, page: (pagination?.page || 0) + 1 }),
      );
      user?.unread && dispatch(putReadById({ orgId: org.id, id: user.id, from: user.fromAddr }));
    }
  }, [mailListStatus, scrollDirection, org, user?.fromAddr, user?.unread, user?.id, dispatch, pagination?.page]);

  const scrollToBottom = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: scrollContainerRef.current.scrollHeight, behavior: 'smooth' });
      setScrollDirection('up');
    }
  }, []);

  useEffect(() => {
    if (mailListStatus === FETCHING_STATUS.BOTTOM) {
      scrollToBottom();
    }
    if (mailListStatus === FETCHING_STATUS.NO_NEW_DATA) {
      setScrollDirection('up');
      setPrevScrollY(0);
    }
    if (
      (mailListStatus === FETCHING_STATUS.DONE || mailListStatus === FETCHING_STATUS.COMPLETED) &&
      scrollContainerRef.current &&
      prevScrollHeight.current !== 0
    ) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight - prevScrollHeight.current;
    }
  }, [mailListStatus, prevScrollHeight, scrollContainerRef]);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const currentScrollY = scrollContainerRef.current.scrollTop;
      if (currentScrollY > prevScrollY && prevScrollY !== 0) {
        setScrollDirection('down');
      } else if (currentScrollY < prevScrollY) {
        setScrollDirection('up');
      }
      setPrevScrollY(currentScrollY);
    }
  }, [prevScrollY]);

  return (
    <div className="cor-chat-content" onScroll={handleScroll} ref={scrollContainerRef}>
      <InfiniteScroller
        useWindow={false}
        initialLoad={false}
        hasMore={scrollDirection === 'down'}
        getScrollParent={() => scrollContainerRef.current}
        threshold={10}
        loadMore={onListScroll}>
        {filter(mails, 'id').length && mailListHasMore ? (
          <Button type="text" loading={!!mailListStatus && mailListStatus > 0} onClick={onLoadMore}>
            Load More
          </Button>
        ) : (
          <></>
        )}
        {mailListStatus !== FETCHING_STATUS.FETCHING && (!mails?.length || !user) && (
          <Empty description="No messages" />
        )}
        {user &&
          filter(mails, 'id')?.map((d) => <ChatContentItem key={d.id} className="cor-chat-contentItem" item={d} />)}
      </InfiniteScroller>
    </div>
  );
};

export default ChatContent;
