import React, { FC, useState, useEffect } from 'react';
import './index.less';
import { ReactComponent as AddIcon } from '@/assets/svg/add.svg';
import { ReactComponent as EditIcon } from '@/assets/svg/edit_category.svg';
import { Form, Button, Modal, Input, Select } from 'antd';
import { useAppDispatch } from '@/features/hooks';
import { CategoryItem } from '@/types/dataAnalysis';
import { addDataAnalysisCategory, updateDataAnalysisCategory } from '@/features/dataAnalysis/actions';

interface IPropTypes {
  className?: string;
  item?: CategoryItem;
  list?: Array<CategoryItem>;
  onCategorySubmit: () => void;
}

const AddCategory: FC<IPropTypes> = ({ item, onCategorySubmit, list = [] }) => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('');
  const [category, setCategory] = useState('');


  const showModal = () => {
    if (item) {
      setColor(item.color);
      setCategory(item.category);
    }else{
      setColor('');
      setCategory('');
    }
    setIsModalVisible(true);
  }
  const handleCancel = () => setIsModalVisible(false);

  const handleSend = async () => {
    setLoading(true);
    try {
      if (item) {
        await dispatch(updateDataAnalysisCategory({ category_id: item.id, category, color }));
      } else {
        await dispatch(addDataAnalysisCategory({ category, color }));
      }
      onCategorySubmit();
    } finally {
      setIsModalVisible(false);
      setLoading(false);
    }
  };

  const availableColors = [
    '#FF5733', '#33FF57', '#3357FF', '#FFFF33', '#FF33FF',
    '#33FFFF', '#FF7F50', '#800080', '#008080', '#FFA500',
    '#FFC0CB', '#A52A2A', '#F0E68C', '#ADFF2F', '#D2691E',
    '#C71585', '#90EE90', '#87CEEB', '#FFD700', '#4B0082',
  ];

  // Create a set of colors that already exist in the list and filter them out from availableColors
  const usedColors = new Set(list.map(item => item.color));
  const filteredColors = availableColors.filter(color => !usedColors.has(color));

  const customLabel = (color: string) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div style={{ width: '12px', height: '12px', backgroundColor: color, borderRadius: '50%' }} />
      <span>{color}</span>
    </div>
  );

  return (
    <div className="cor-addCategory">
      {item ? (
        <EditIcon className="cor-addCategory-edit" onClick={showModal} />
      ) : (
        <Button className="cor-addCategory-btn" icon={<AddIcon />} type="primary" onClick={showModal}>
          Add Category
        </Button>
      )}
      <Modal
        title="Add Category"
        open={isModalVisible}
        onCancel={handleCancel}
        className="comment-modal"
        footer={null}
      >
        <Form
          onFinish={handleSend}
        >
          <Form.Item
            name="category"
            rules={[{ required: true, message: 'Please enter a category!' }]}
          >
            <Input
              className="comment-input"
              placeholder="Enter Category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="color"
            rules={[{ required: true, message: 'Please select a color!' }]}
          >
            <Select
              value={color || undefined}
              placeholder="Choose Color"
              style={{ width: 150 }}
              onChange={setColor}
            >
              {filteredColors.map((color) => (
                <Select.Option key={color} value={color} label={customLabel(color)}>
                  {customLabel(color)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
            style={{width:"100%"}}
              loading={loading}
              disabled={loading}
              type="primary"
              htmlType="submit"
              className="send-button"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddCategory;
