/**
 * @description Component-MailSettings
 */
import React, { FC } from 'react';
import './index.less';

import MailSettingCard from './MailSettingCard';
import ShopConnectionsTable from '@/pages/Dashboard/Settings/Connections/ShopConnectionsTable';
import SocialSettingCard from './SocialSettingCard';
import { SHOP_TYPES } from '@/constants/shop';
interface IPropTypes {
  className?: string;
}
const Connections: FC<IPropTypes> = function () {
  return (
    <div className="cor-connections">
      <MailSettingCard />
      <div className="cor-connections-divider" />
      <ShopConnectionsTable type={SHOP_TYPES.SHOPIFY} />
      {/* <div className="cor-connections-divider" />
      <SocialSettingCard /> */}
    </div>
  );
};

export default Connections;
