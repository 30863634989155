/**
 * @description Component-UserSearch
 */
import React, { FC } from 'react';
import './index.less';
import { Search } from '@/components/Search';
import { useAppDispatch } from '@/features/hooks';
import { ReactComponent as SearchIcon } from '@/assets/svg/icon_search_linear.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/icon_close_24.svg';
import { useOrg } from '@/features/settings/hooks';
import { setMailboxFilter } from '@/features/inbox/actions';
import { useDebounceFn } from 'ahooks';
interface IPropTypes {
  className?: string;
}
const UserSearch: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const { run: searchMail } = useDebounceFn(
    (searchStr: string) => {
      org &&
        dispatch(
          setMailboxFilter({
            orgId: org.id,
            filter: searchStr,
          }),
        );
    },
    { wait: 200 },
  );
  return (
    <div className="cor-user-search">
      <Search
        placeholder="Search Emails..."
        allowClear={{
          clearIcon: <CloseIcon />,
        }}
        prefix={<SearchIcon width={16} height={16} />}
        onChange={(e) => searchMail(e.target.value.trim())}
      />
    </div>
  );
};

export default UserSearch;
