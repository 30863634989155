/**
 * @description Component-MailboxTabs
 */
import React, { FC, useCallback, useMemo } from 'react';
import './index.less';
import { Tabs } from 'antd';
import { MAIL_BOX_TYPE } from 'src/constants/inbox'; // Ensure to import the enum
import { useAppDispatch } from '@/features/hooks';
import { useOrg } from '@/features/settings/hooks';
import { setMailboxFilter } from '@/features/inbox/actions';
import { useMailboxFilter } from '@/features/inbox/hooks';
import { filter } from 'lodash';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
const MailboxTabs: FC<IPropTypes> = function ({}) {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const filter = useMailboxFilter(org?.id);
  const tabItems = useMemo(
    () =>
      Object.values(MAIL_BOX_TYPE).map((type) => ({
        key: type,
        label: type,
      })),
    [],
  );
  const updateFilter = useCallback(
    (key: string) => {
      const filterNew = filter;
      switch (key) {
        case MAIL_BOX_TYPE.INBOX:
          filterNew.isDraft = false;
          filterNew.archived = false;
          break;
        case MAIL_BOX_TYPE.ARCHIVED:
          filterNew.isDraft = false;
          filterNew.archived = true;
          break;
        default:
          filterNew.isDraft = true;
          filterNew.archived = false;
      }

      org &&
        dispatch(
          setMailboxFilter({
            orgId: org.id,
            filter: filterNew,
          }),
        );
    },
    [dispatch, filter, org],
  );

  return <Tabs onChange={updateFilter} className="cor-mailbox-tabs" items={tabItems} />;
};

export default MailboxTabs;
