import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import './index.less';
import DocumentsTabs from './DocumentsTabs';
import DocumentsCard from './DocumentsCard';
import DocumentsDetail from './DocumentsDetail';
import DocumentsEdit from './DocumentsEdit';
import DocumentsSidebar from './DocumentsSidebar';
import { useKnowledgebaseTags } from '@/features/knowledge/hooks';
import { getKnowledgebase } from '@/features/knowledge/actions';
import { useAppDispatch } from '@/features/hooks';
import { useKnowledgebase } from '@/features/knowledge/hooks';
import { Spin } from 'antd'; // 引入Spin组件作为loading
interface IPropTypes {
  className?: string;
  tabs: string;
}

const Documents = forwardRef((props: IPropTypes, ref) => {
  const dispatch = useAppDispatch();
  const [isEdit, setEditChange] = useState(false);
  const [status, setStatus] = useState('published');
  const [tags, setTags] = useState<{ name: string; id: string }>();
  const [isDetail, setDetailChange] = useState(false);
  const tagsList = useKnowledgebaseTags();
  const [knowledgebaseTagId, setKnowledgeTagsId] = useState('');
  const [knowledgebaseId, setKnowledgeId] = useState('');
  const knowledgeList = useKnowledgebase() || [];
  const [loading, setLoading] = useState(true);

  // 暴露给父组件的功能，通过 ref 调用
  useImperativeHandle(ref, () => ({
    handleEditChange(val: boolean, id: string) {
      setEditChange(val);
      setKnowledgeId(id);
    },
  }));

  useEffect(() => {
    if (tagsList) {
      setLoading(false);
    }
    if (tagsList && tagsList.length > 0) {
      setKnowledgeTagsId(tagsList[0]?.id);
      setTags(tagsList[0]);
      dispatch(getKnowledgebase({category: props.tabs === 'documents' ? 'document,file' : 'url', pageSize: 1000, tag: tags?.name,status: status, }));
    }
  }, [tagsList]); // 依赖项为 tagsList

  const handleEditChange = (val: boolean, id: string) => {
    setEditChange(val);
    setKnowledgeId(id);
    if (tagsList && tagsList.length > 0) {
      dispatch(
        getKnowledgebase({
          category: props.tabs === 'documents' ? 'document,file' : 'url',
          pageSize: 1000,
          tag: tagsList[0]?.id,
          status: status,
        }),
      );
    }
  };
  const handleKnowledgeIdChange = (id: string) => {
    setKnowledgeId(id);
    setDetailChange(true);
  };
  const handleStatusChange = (val: string) => {
    setDetailChange(false);
    setKnowledgeId('');
    setStatus(val);
    if (tags) {
      dispatch(
        getKnowledgebase({
          category: props.tabs === 'documents' ? 'document,file' : 'url',
          pageSize: 1000,
          tag:tags.name ,
          status: val,
        }),
      );
    }
  };

  const handleTagsIdChange = (item: { name: string; id: string }) => {
    setKnowledgeId('');
    setDetailChange(false);
    setTags(item);
    dispatch(
      getKnowledgebase({
        category: props.tabs === 'documents' ? 'document,file' : 'url',
        pageSize: 1000,
        tag:item.name,
        status: status,
      }),
    );
  };

  return (
    <div className="cor-documents">
      {/* 如果loading为true，则显示加载动画 */}
      {loading && (
        <div className="cor-documents-loading">
          <Spin size="large" /> {/* Ant Design的Spin组件 */}
        </div>
      )}
      {!loading && (
        <div className="cor-documents-center">
          <div className="cor-documents-center-left">
            <DocumentsTabs onStatusChange={handleStatusChange} />
            <DocumentsSidebar
              tabs={props.tabs}
              tag={tags ? tags : { name: '', id: '' }}
              status={status}
              tagsIdChange={handleTagsIdChange}
            />
          </div>
          {isEdit ? (
            <DocumentsEdit knowledgeId={knowledgebaseId} onEditChange={handleEditChange} status={status} tabs={props.tabs}/>
          ) : (
            <div>
              {knowledgebaseTagId && !isDetail && knowledgeList?.length > 0 ? (
                <DocumentsCard onIdChange={handleKnowledgeIdChange} tag={tags ? tags.name : ''} status={status} />
              ) : (
                <DocumentsDetail knowledgeId={knowledgebaseId} onEditChange={handleEditChange} status={status} tabs={props.tabs}/>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

Documents.displayName = 'Documents';

export default Documents;
