/**
 * @description Component-UserList
 */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Empty } from 'antd';

import { useMailSettings, useMailSettingsStatus, useOrg } from '@/features/settings/hooks';
import {
  useCurrentMailboxId,
  useMailboxFilter,
  useMailboxList,
  useMailboxListPagination,
  useMailboxListStatus,
} from '@/features/inbox/hooks';
import { useAppDispatch } from '@/features/hooks';
import {
  getArchivedById,
  getMailboxList,
  getUserMailList,
  setInboxCurrentId,
  putReadById,
  getMailUserList,
} from '@/features/inbox/actions';

import { FETCHING_STATUS, SETTINGS_TABS } from '@/constants';
import { IMailbox } from '@/types/inbox';
import ListSkeleton from '@/pages/components/ListSkeleton';
import { useNavigate } from 'react-router-dom';
import ListItem from '@/components/ListItem';
import './index.less';
import { pollingFunc } from '@/utils/axios';
import Paginator from '@/pages/components/Paginator';
import { REQUEST_PAGE_SIZE } from '@/constants/plugin';
import _ from 'lodash';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}
let polling: NodeJS.Timeout;
const MailboxList: FC<IPropTypes> = function () {
  const intervalRef = useRef<number | null>(null);
  const org = useOrg();
  const mailboxes = useMailboxList(org?.id);
  const userStatus = useMailboxListStatus(org?.id);
  const mailboxPagination = useMailboxListPagination(org?.id);
  const dispatch = useAppDispatch();
  const filter = useMailboxFilter(org?.id);
  const currentId = useCurrentMailboxId(org?.id);
  const listRef = useRef<HTMLDivElement>(null);
  const firstUserRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const mailSettingsStatus = useMailSettingsStatus(org?.id);
  const mailSettings = useMailSettings(org?.id);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ page: 1, pageSize: REQUEST_PAGE_SIZE });

  const selectMailbox = useCallback(
    (mailbox?: IMailbox) => {
      if (!mailbox) {
        return;
      }
      org && dispatch(setInboxCurrentId({ orgId: org?.id, id: mailbox.id }));
      org && dispatch(getUserMailList({ orgId: org?.id, from: mailbox.fromAddr, page: 1 }));
      org && mailbox?.unread && dispatch(putReadById({ orgId: org.id, id: mailbox.id, from: mailbox.fromAddr }));
    },
    [dispatch, org],
  );

  const onLoad = useCallback(async () => {
    org &&
      pagination &&
      dispatch(
        getMailboxList({
          ...pagination,
          orgId: org.id,
          ...filter,
        }),
      );
    org &&
      pagination &&
      dispatch(
        getMailUserList({
          ...pagination,
          orgId: org.id,
          ...filter,
        }),
      );
  }, [org, dispatch, pagination, filter]);

  useEffect(() => {
    if (polling) {
      clearInterval(polling);
    }
    polling = pollingFunc(() => {
      onLoad();
    }, 60 * 1000);

    return () => {
      if (polling) {
        clearInterval(polling);
        intervalRef.current = null;
      }
    };
  }, [onLoad]);

  const onSplice = (e: React.MouseEvent<HTMLElement, MouseEvent>, user: IMailbox) => {
    e.stopPropagation();
    if (org) {
      dispatch(getArchivedById({ orgId: org.id, id: user.id }));
      // dispatch(spliceUserListByFrom({ orgId: org.id }));
      dispatch(setInboxCurrentId({ orgId: org?.id, id: '' }));
    }
  };
  useEffect(() => {
    org && !currentId && !!mailboxes?.length && selectMailbox(_.first(mailboxes));
  }, [mailboxes]);

  return (
    <div className="cor-mailbox-list-wrap" ref={scrollContainerRef}>
      {userStatus === FETCHING_STATUS.FETCHING && !mailboxes?.length && <ListSkeleton />}
      {mailSettingsStatus === FETCHING_STATUS.DONE &&
        !!mailSettings.length &&
        (userStatus === FETCHING_STATUS.DONE || userStatus === FETCHING_STATUS.COMPLETED) &&
        !mailboxes?.length && (
          <Empty
            description={
              <>
                <div>Please wait</div>
                <div>Updating mails may take up to 3 mins...</div>
              </>
            }
          />
        )}
      {!mailSettings.length && mailSettingsStatus === FETCHING_STATUS.DONE && (
        <Empty
          description={
            <>
              <div>No mail connected!</div>
              <div>
                Please set up mail connection first!{' '}
                <a onClick={() => navigate(`/dashboard/settings/${SETTINGS_TABS.CONNECTIONS}`)}>Settings</a>
              </div>
            </>
          }
        />
      )}
      <div className="cor-mailbox-list-wrap-items">
        {mailboxes?.map((mailbox, index) => (
          <ListItem
            ref={index === 0 ? firstUserRef : listRef}
            key={mailbox.id}
            item={mailbox}
            currentId={currentId}
            onSelect={selectMailbox}
            onArchive={onSplice}
          />
        ))}
      </div>
      {!!mailboxPagination?.total && (
        <Paginator
          total={mailboxPagination.total}
          pageSize={REQUEST_PAGE_SIZE}
          current={mailboxPagination.page}
          onChange={(page: number, pageSize: number) => {
            setPagination({ page, pageSize });
          }}
        />
      )}
    </div>
  );
};

export default MailboxList;
