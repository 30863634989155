import React, { FC, useEffect, useMemo, useState } from 'react';
import './index.less';
import { useOrg } from '@/features/settings/hooks';
import { useSelectTicketGetParam, useSelectTicketListTotal, useTicketsStatus } from '@/features/plugin/hooks';
import { FETCHING_STATUS } from '@/constants';
import { Table, ConfigProvider, Empty } from 'antd';
import { CategoryItem } from '@/types/dataAnalysis';
import { ColumnType, TableProps } from 'antd/lib/table';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/features/hooks';
import DeleteData from './DeleteData';
import Search from './search';
import AddCategory from './AddCategory';
import { getDataAnalysisCategory } from '@/features/dataAnalysis/actions';

interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
}

const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

const tableEmptyRender = () => (
  <Empty
    className="cor-settings-empty"
    description="No categories available."
  />
);

const CarSettings: FC<IPropTypes> = () => {
  const dispatch = useAppDispatch();
  const org = useOrg();
  const [data, setData] = useState<CategoryItem[]>([]); // 使用状态存储数据
  const dataStatus = useTicketsStatus(org?.id);
  const param = useSelectTicketGetParam();
  const total = useSelectTicketListTotal();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getDataAnalysisCategory({ category: '' })); // Pass the required parameter
      if (response && response.payload) {
        console.log(response);
        setData(response.payload);
      }
    };

    fetchData();
  }, [dispatch]);

  const columDefs: ColumnType<CategoryItem>[] = useMemo(
    () => [
      {
        title: 'Category Name',
        key: 'category',
        align: 'left',
        render: (_value, record) => record.category,
        width: 100,
      },
      {
        title: 'Number Sorted',
        key: 'sort',
        ellipsis: true,
        sorter: (a, b) => (a.sort ?? a.index) - (b.sort ?? b.index),
        render: (_value, record, index) => record.sort ?? index + 1,
        width: 150,
      },
      {
        title: 'Color',
        key: 'color',
        ellipsis: true,
        sorter: (a, b) => a.color.localeCompare(b.color),
        render: (_value, record) => <div className="cor-settings-color" style={{ background: record.color }}></div>,
        width: 100,
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        width: 100,
        render: (_value, record) => (
          <div className="cor-settings-actions">
            <AddCategory item={record} onCategorySubmit={handleCategorySubmit} />
            <DeleteData id={record.id} onCategorySubmit={handleCategorySubmit} />
          </div>
        ),
        onCell: () => ({
          onClick: stopPropagation, // 阻止事件冒泡
        }),
      },
    ],
    [param.sort],
  );

  const pagination = useMemo(
    () => ({
      pageSize: 30,
      total,
      showTotal: (total: number) => `Total data ${total}`,
      showSizeChanger: true,
    }),
    [total],
  );

  const onChange: TableProps<CategoryItem>['onChange'] = (pagination, _filters, sorter, _extra) => {
    if (sorter.order) {
      const sortedData = [...data].sort((a, b) => {
        const order = sorter.order === 'ascend' ? 1 : -1;
        if (sorter.field === 'name') {
          return a.name.localeCompare(b.name) * order;
        }
        if (sorter.field === 'sort') {
          return (a.sort - b.sort) * order;
        }
        return 0;
      });
      setData(sortedData); // 更新排序后的数据
    }
  };

  const handleCategorySubmit = async (e: any) => {
    const response = await dispatch(getDataAnalysisCategory({ category: e ? e : '' }));
    if (response && response.payload) {
      setData(response.payload); // 更新数据状态
    }
  };

  const rowClassName = () => 'custom-table-row';

  return (
    <div className="cor-settings">
      <div className="cor-settings-head">
        <Search onCategorySubmit={handleCategorySubmit}/>
        <AddCategory onCategorySubmit={handleCategorySubmit} list={data}/>
      </div>
      <ConfigProvider renderEmpty={tableEmptyRender}>
        <Table
          loading={dataStatus === FETCHING_STATUS.FETCHING && !data.length}
          rowKey={(record) => record?.id + record.updatedAt}
          columns={columDefs}
          dataSource={data}
          pagination={pagination}
          className="cor-settings-table"
          rowClassName={rowClassName}
          onChange={onChange}
        />
      </ConfigProvider>
    </div>
  );
};

export default CarSettings;
