/**
 * @description Component-CategorySelect
 */
import React, { FC, useCallback, useState } from 'react';
import './index.less';
import { Select } from 'antd';
import { DataAnalysis } from '@/types/dataAnalysis';
import { useAppDispatch } from '@/features/hooks';
// import { updateTicket } from '@/features/plugin/actions';
import { useOrg } from '@/features/settings/hooks';
import _ from 'lodash';
import classNames from 'classnames';
interface Category {
  category: string;
  color: string;
  id?: string;
}
interface IPropTypes {
  children?: React.ReactNode;
  className?: string;
  category: string;
  categories: Category[];
  onCategoryChange: (category: string) => void; 
}

const customLabel = (cat: Category) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
    <div
      style={{
        width: '12px',
        height: '12px',
        backgroundColor: cat.color,
        borderRadius: '50%',
      }}
    />
    <span>{cat.category}</span>
  </div>
);
const CategorySelect: FC<IPropTypes> = function ({ category, categories ,onCategoryChange}) {
  const org = useOrg();
  const dispatch = useAppDispatch();
  const [changing, setChanging] = useState(false);
  
  const changeCategory = (val: string) => {
    console.log(val);
    onCategoryChange(val);
  }

  return (
    <Select
      className='cor-category-select'
      loading={changing}
      value={category}
      onChange={(val) => changeCategory(val)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      placeholder="Choose Color"
      style={{ width: 150 }}
      optionLabelProp="label">
      {/* Filter out the selected colors */}
      {categories.map((cat) => (
        <Select.Option key={cat.id} value={cat.category} label={customLabel(cat)}>
          {customLabel(cat)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CategorySelect;
