/**
 * @description Component-search
 */
import React, { FC } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.less';
import { getKnowledgebase } from '@/features/knowledge/actions';
import { useAppDispatch } from '@/features/hooks';

interface IPropTypes {
  className?: string;
}

const Search: FC<IPropTypes> = function () {
  const dispatch = useAppDispatch();

  const handleSearch = (value: string) => {
    if (value) {
      dispatch(
        getKnowledgebase({
          category: tabs === 'documents' ? 'document,file' : 'url',
          tag: tag.id,
          status,
          search: value,
        }),
      );
    }
  };

  return (
    <div className="cor-data-search">
      <Input
        placeholder="Search Reports..."
        prefix={<SearchOutlined className="search-icon" />}
        allowClear
        className="search-input"
        onPressEnter={(e) => handleSearch((e.target as HTMLInputElement).value)}
      />
    </div>
  );
};

export default Search;
