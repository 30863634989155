/**
 * @description Component-search
 */
import React, { FC } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.less';

interface IPropTypes {
  className?: string;
  onSearch: (value: string) => void;
}

const Search: FC<IPropTypes> = function ({ onSearch }) {

  const handleSearch = (value: string) => {
   onSearch(value);
  };

  return (
    <div className="cor-data-search">
      <Input
        placeholder="Search Data..."
        prefix={<SearchOutlined className="search-icon" />}
        allowClear
        className="search-input"
        onPressEnter={(e) => handleSearch((e.target as HTMLInputElement).value)}
      />
    </div>
  );
};

export default Search;
