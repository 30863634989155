/**
 * @description Component-DeleteData
 */
import React, { FC, useCallback } from 'react';
import './index.less';
import { ReactComponent as DeleteIcon } from '@/assets/svg/icon_table_delete.svg';
import { Button } from 'antd';
interface IPropTypes {
  className?: string;
  id: string; 
  onDelete: (id: string) => void;
}
const DeleteData: FC<IPropTypes> = function ({ id, onDelete }) {
  const delMember = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <Button
      onClick={delMember}
      icon={<DeleteIcon />}
      className="cor-delete-ticket-button"></Button>
  );
};

export default DeleteData;
