/**
 * @description Component-Chating
 */
import React, { FC } from 'react';
import './index.less';
import AigcBot from '@/components/AigcBot';
import Avatar from 'react-avatar';
import { AIGC_TYPE } from '@/constants/aigc';
interface IPropTypes {
  className?: string;
  userAvatar?: string;
  userName?: string;
  userEmail?: string;
  conversationId?: string;
  showPilotBot?: boolean;
}

const MailboxDetailHeader: FC<IPropTypes> = function ({
  userAvatar,
  userName,
  userEmail,
  conversationId,
  showPilotBot = true,
}) {
  return (
    <div className="cor-mailbox-detail-header">
      <div className="cor-mailbox-detail-header-left">
        <Avatar round size="48" src={userAvatar} name={userName || userEmail} />
        <div className="cor-mailbox-detail-header-right">
          <span className="name">{userName}</span>
          <span className="email">{userEmail}</span>
        </div>
      </div>
      {showPilotBot && conversationId && (
        <div className="cor-mailbox-detail-header-ai">
          <AigcBot
            scene={AIGC_TYPE.MAIL_ASSISTANT}
            customerName={userName}
            customerEmail={userEmail}
            conversationId={conversationId}
          />
        </div>
      )}
    </div>
  );
};

export default MailboxDetailHeader;
