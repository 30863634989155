import {
  IMailSetting,
  IMailSettingResponse,
  IMailSignature,
  IOrganization,
  IResponse,
  ITemplate,
  ISocialSetting,
  IAutoReplySettings,
} from '@/types/settings';
import { axiosDelete, axiosGet, axiosPost } from '@/utils/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { resetMeta } from '../inbox/actions';

export const getOrg = createAsyncThunk('settings/getOrg', async (): Promise<IOrganization | undefined> => {
  try {
    const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `organization`);
    return data.data;
  } catch (e) {
    console.log('🚀 ~ file: actions.ts:18 ~ getOrg ~ e:', e);

    throw e;
  }
});

export const getOrgMailSettings = createAsyncThunk(
  'settings/getOrgMailSettings',
  async ({}: { org: string }): Promise<Record<string, IMailSetting> | undefined> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `mailconfig`);
      return _.keyBy(data.data, 'id');
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const saveOrgMailSettings = createAsyncThunk(
  'settings/saveOrgMailSettings',
  async ({ imap, org }: { imap: IMailSetting; org: string }, { dispatch }): Promise<IMailSettingResponse> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `mailconfig`, {
        ...imap,
        secure: true,
      });
      org && dispatch(getOrgMailSettings({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const deleteOrgMailSettings = createAsyncThunk(
  'settings/deleteOrgMailSettings',
  async (
    { setting, org }: { setting: IMailSetting; org: string | undefined },
    { dispatch },
  ): Promise<IResponse<any>> => {
    try {
      const { data } = await axiosDelete(process.env.REACT_APP_API_ROOT_V2 + `mailconfig`, {
        ...setting,
      });
      org && dispatch(getOrgMailSettings({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getUploadPresign = createAsyncThunk('settings/getUploadPresign', async (): Promise<string> => {
  try {
    const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `s3/presign`, { type: 'put' });
    return data.data;
  } catch (e) {
    console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
    throw e;
  }
});
export const parseUploadedFile = createAsyncThunk(
  'settings/parseUploadedFile',
  async ({ url, ext }: { url: string; ext: string }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `s3/file/parse`, { url, ext });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getSignature = createAsyncThunk(
  'settings/getSignature',
  async ({}: { org: string | undefined }): Promise<IResponse<Record<string, IMailSignature>>> => {
    try {
      // const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `mail/signature`);
      // return { ...data, data: _.keyBy(data.data, 'id') };
    } catch (e) {
      console.log('🚀 ~ file: actions.ts ~ getSignature ~ (e:', e);
      throw e;
    }
  },
);
export const saveSignature = createAsyncThunk(
  'settings/saveSignature',
  async ({ signature }: { org: string | undefined; signature: IMailSignature }): Promise<IResponse<IMailSignature>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `mail/save_signature`, {
        ...signature,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts ~ saveSignature ~ (e:', e);
      throw e;
    }
  },
);
export const deleteSignature = createAsyncThunk(
  'settings/deleteSignature',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `mail/signature/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions ~ deleteSignature ~ (e:', e);
      throw e;
    }
  },
);
export const getTemplate = createAsyncThunk(
  'settings/getTemplate',
  async ({}: { org: string | undefined }): Promise<IResponse<Record<string, ITemplate>>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template`);
      return { ...data, data: _.keyBy(data.data, 'id') };
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const saveTemplate = createAsyncThunk(
  'settings/saveTemplate',
  async ({ template }: { org: string | undefined; template: ITemplate }): Promise<IResponse<ITemplate>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template`, {
        ...template,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const perfectPrompt = createAsyncThunk(
  'settings/perfectPrompt',
  async ({ prompt }: { prompt: string }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template/perfect`, {
        prompt,
      });
      const perfectedPrompt = data.data.promptPerfect;
      return perfectedPrompt;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const updateTemplate = createAsyncThunk(
  'settings/updateTemplate',
  async ({ template }: { org: string | undefined; template: ITemplate }): Promise<IResponse<ITemplate>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template/update`, {
        ...template,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const deleteTemplate = createAsyncThunk(
  'settings/deleteTemplate',
  async ({ id }: { org: string | undefined; id: string }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template/delete`, {
        id,
      });
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const setDefaultTemplate = createAsyncThunk(
  'settings/setDefaultTemplate',
  async ({ id, org }: { org: string | undefined; id: string }, { dispatch }): Promise<IResponse<boolean>> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `llm/prompt_template/default`, {
        id,
      });
      dispatch(getTemplate({ org }));
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ set default ~ (e:', e);
      throw e;
    }
  },
);
export const getGmailAuthorizeUrl = createAsyncThunk(
  'settings/getGmailAuthorizeUrl',
  async ({}: { org: string | undefined }): Promise<string> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `mail/gmail/authorize`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const postGmailAuthorizeCallback = createAsyncThunk(
  'settings/postGmailAuthorizeCallback',
  async ({ code, scope }: { code: string; scope: string }): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `mail/gmail/authorize/callback`, {
        code,
        scope,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);

export const getOutlookAuthorizeUrl = createAsyncThunk(
  'settings/getOutlookAuthorizeUrl',
  async ({}: { org: string | undefined }): Promise<string> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `mail/outlook/authorize`);
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ getOutlookAuthorizeUrl ~ (e:', e);
      throw e;
    }
  },
);

export const postOutlookAuthorizeCallback = createAsyncThunk(
  'settings/postOutlookAuthorizeCallback',
  async ({ code, client_info }: { code: string; client_info: string }): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `mail/outlook/authorize/callback`, {
        code,
        client_info,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:10 ~ postOutlookAuthorizeCallback ~ (e:', e);
      throw e;
    }
  },
);

export const getOrgSocialSettings = createAsyncThunk(
  'settings/getOrgSocialSettings',
  async (): Promise<IResponse<ISocialSetting>> => {
    try {
      const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `meta/user`);
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:469 ~ getOrgSocialSettings ~ (e:', e);
      throw e;
    }
  },
);

export const saveOrgSocialSettings = createAsyncThunk(
  'settings/saveOrgSocialSettings',
  async (
    { userID, accessToken }: { userID: string; accessToken: string; org: string | undefined },
    { dispatch },
  ): Promise<boolean> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `meta/user`, {
        userId: userID,
        userToken: accessToken,
      });
      dispatch(getOrgSocialSettings());
      return data.data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:489 ~ saveOrgSocialSettings ~ (e:', e);
      throw e;
    }
  },
);

export const deleteOrgSocialSettings = createAsyncThunk(
  'settings/deleteOrgSocialSettings',
  async ({}: { orgId?: string }, { dispatch }): Promise<string> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `meta/user/delete`);
      dispatch(getOrgSocialSettings());
      dispatch(resetMeta());
      return data;
    } catch (e) {
      console.log('🚀 ~ file: actions.ts:503 ~ getEmailList ~ (e:', e);
      throw e;
    }
  },
);
export const getAutoReplySettings = createAsyncThunk(
  'settings/getAutoReplySettings',
  async ({}: { orgId: string }): Promise<IAutoReplySettings | undefined> => {
    try {
      // const { data } = await axiosGet(process.env.REACT_APP_API_ROOT_V2 + `autoreply`);
      // return data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
export const updateAutoReplySetting = createAsyncThunk(
  'settings/updateAutoReplySetting',
  async ({ settings }: { orgId: string; settings: IAutoReplySettings }): Promise<IAutoReplySettings | undefined> => {
    try {
      const { data } = await axiosPost(process.env.REACT_APP_API_ROOT_V2 + `autoreply`, {
        ...settings,
      });
      return data.data;
    } catch (e) {
      console.log('🚀 ~ e:', e);
      return undefined;
    }
  },
);
