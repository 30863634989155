/**
 * @description Component-InboxGmail
 */
import React, { FC } from 'react';
import './index.less';
import Chat from './Chat';
import MailboxList from './MailboxList';
import UserSearch from './UserSearch';
import SendMailModal from '@/components/SendMailModal';
import { MAIL_BTN_TYPE } from '@/constants/inbox';
import useResizableSidebar from '@/hooks/useResizableSidebar';
import { useOrg } from '@/features/settings/hooks';
import { useCurrentMailboxId, useMailboxById } from '@/features/inbox/hooks';
import { useCurrentConversationId } from '@/features/aigc/hooks';
import MailboxDetailHeader from './MailboxDetailHeader';
import MailboxTabs from './MailboxTabs/indext';
interface IPropTypes {
  className?: string;
  isSidebarOpen?: boolean;
}
const InboxMail: FC<IPropTypes> = function ({ isSidebarOpen = true }) {
  const org = useOrg();
  const currentMailboxId = useCurrentMailboxId(org?.id);
  const currentMailbox = useMailboxById(org?.id, currentMailboxId);
  const conversationId = useCurrentConversationId(org?.id);
  const { sidebarWidth, isResizerVisible, handleMouseDown, handleMouseEnter, handleMouseLeave } = useResizableSidebar();

  return (
    <div className="cor-inbox-mail-chat">
      <div className={`cor-inbox-mail-chat-left ${isSidebarOpen ? 'open' : 'closed'}`} style={{ width: sidebarWidth }}>
        <div className="cor-inbox-mail-chat-left-header">
          <MailboxTabs />
          <div className="cor-inbox-mail-chat-left-header-bottom">
            <UserSearch /> <SendMailModal type={MAIL_BTN_TYPE.NEW} />
          </div>
        </div>
        <MailboxList />
        <div
          className={`cor-inbox-mail-chat-left-resizer ${isResizerVisible ? 'visible' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
      <div className="cor-inbox-mail-chat-right">
        {currentMailboxId ? (
          <MailboxDetailHeader
            userName={currentMailbox?.name || currentMailbox?.fromName}
            userEmail={currentMailbox?.fromAddr}
            userAvatar={currentMailbox?.avatar}
            conversationId={conversationId}
          />
        ) : (
          <></>
        )}
        <Chat />
      </div>
    </div>
  );
};

export default InboxMail;
