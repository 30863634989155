/**
 * @description Component-Paginator
 */
import React, { FC } from 'react';
import './index.less';
import { Pagination, PaginationProps } from 'antd';

const Paginator: FC<PaginationProps> = function (props) {
  return (
    <Pagination
      {...props}
      showSizeChanger={false}
      simple={true}
      showQuickJumper={false}
      showTotal={(total: number, range: [number, number]) => (
        <div className="cor-paginator-range">
          {range[0]} - {range[1]} of {total}
        </div>
      )}
      className="cor-paginator"
    />
  );
};

export default Paginator;
