/**
 * @description Component-SessionDetail
 */
import React, { FC, useEffect, useMemo, useRef } from 'react';
import './index.less';
import { useSelectedSession, useSessionConversations, useSessionConversationsStatus } from '@/features/plugin/hooks';
import { useOrg } from '@/features/settings/hooks';
import { useAppDispatch } from '@/features/hooks';
import { getSessionConversation } from '@/features/plugin/actions';
import { FETCHING_STATUS } from '@/constants';
import ConversationItem from '../ConversationItem';
import SessionDetailSkeleton from './SessionDetailSkeleton';
import { pollingFunc } from '@/utils/axios';
import { IMessageRole, PLUGIN_SESSION_POLLING_INTERVAL } from '@/constants/plugin';
import classNames from 'classnames';
import { useMediaQueryDevice } from '@/components/MediaQuery';
import PluginReplyBox from '../PluginReplyBox';
import _ from 'lodash';
import AigcBot from '@/components/AigcBot';
import { AIGC_TYPE } from '@/constants/aigc';
import MailboxDetailHeader from '@/pages/Inbox/InboxMail/MailboxDetailHeader';
interface IPropTypes {
  className?: string;
}
let polling: NodeJS.Timeout;
const SessionDetail: FC<IPropTypes> = function () {
  const org = useOrg();
  const { deviceType } = useMediaQueryDevice();
  const session = useSelectedSession(org?.id);
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const conversations = useSessionConversations(session?.conversationId);
  const lastUserQuestion = useMemo(
    () => _.last(conversations?.filter((c) => c.role === IMessageRole.CUSTOMER)),
    [conversations],
  );
  const conversationsFiltered = useMemo(() => conversations?.filter((c) => !!c.content), [conversations]);
  const conversationsStatus = useSessionConversationsStatus(session?.conversationId);
  useEffect(() => {
    if (polling) {
      clearInterval(polling);
    }
    polling = pollingFunc(() => {
      org && session && dispatch(getSessionConversation({ orgId: org?.id, conversationId: session.conversationId }));
    }, PLUGIN_SESSION_POLLING_INTERVAL);
    return () => polling && clearInterval(polling);
    // only these three
  }, [dispatch, org, session?.conversationId]);
  if (!session) {
    return null;
  }
  return (
    <div className="cor-session-detail-wrap">
      <MailboxDetailHeader userName={session.email} userEmail={session.email} conversationId={session.conversationId} />
      <AigcBot
        scene={AIGC_TYPE.LIVECHAT_ASSISTANT}
        conversationId={session?.conversationId}
        customerEmail={session?.email}
        customerName={session?.email}
      />
      <div className={classNames('cor-session-detail', deviceType)} ref={containerRef}>
        {conversationsStatus === FETCHING_STATUS.FETCHING && !conversations?.length && <SessionDetailSkeleton />}
        {conversationsFiltered?.map((c) => (
          <ConversationItem conversation={c} key={c.id} />
        ))}
      </div>
      <PluginReplyBox session={session} lastCustomerQuestion={lastUserQuestion?.content} />
    </div>
  );
};

export default SessionDetail;
