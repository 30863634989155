import React, { useEffect, useState } from 'react';
import { Radio, Button, Typography, Divider, message, Spin } from 'antd';
import './index.less';

const { Text } = Typography;
import { useAppDispatch } from '@/features/hooks';
import { getDataAnalysisCategory,getDataAnalysisMailboxDetail, updateDataAnalysisMailbox } from '@/features/dataAnalysis/actions';
interface Category {
  category: string;
  color: string;
  id?: string; 
}

interface DataPayload {
  total: number;
  current: number;
  next_mailbox_id: string|null;
  previous_mailbox_id: string|null;
  mailbox_detail: {
    aiCategory?: string;
    html?: string;
    id: string;
    fromAddr: string;
    subject: string;
  };
}

const EmailCategorization: React.FC = () => {
  const dispatch = useAppDispatch();
  const [category, setCategory] = useState<string | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const [data, setData] = useState<DataPayload | null>(null);
  const [mailbox_id, setMailbox_id] = useState<string | null>('1');
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    const fetchData = async () => {
      setCategory(null);
      setLoading(true);
      const dataResponse = await dispatch(getDataAnalysisMailboxDetail({ mailbox_id: mailbox_id as string })); 
      if (dataResponse && dataResponse.payload.data) {
        console.log(dataResponse.payload);
        if (dataResponse.payload.data.mailbox_detail.aiCategory) {
          setCategory(dataResponse.payload.data.mailbox_detail.aiCategory);
        }
        setData(dataResponse.payload.data);
      }

      const categoryResponse = await dispatch(getDataAnalysisCategory({ category: '' })); 
      if (categoryResponse && categoryResponse.payload) {
        console.log(categoryResponse);
        setCategories(categoryResponse.payload);
      }
      console.log('categories', categories);
      setLoading(false);
    };
    fetchData();
  }, [dispatch, mailbox_id]);
  const handleCategoryChange = (e: any) => {
    setCategory(e.target.value);
  };

  const handlePreviousEmail = () => {
    if (data?.previous_mailbox_id) {
      if (category) {
        dispatch(updateDataAnalysisMailbox({ mailbox_id: data.mailbox_detail.id, category }));
      }
      setMailbox_id(data.previous_mailbox_id);
    } else {
      message.info('No previous email available.'); 
    }
  };

  const handleNextEmail = () => {
    if (data?.next_mailbox_id) {
      if (category) {
        dispatch(updateDataAnalysisMailbox({ mailbox_id: data.mailbox_detail.id, category }));
      }
      setMailbox_id(data.next_mailbox_id); 
    } else {
      message.info('No next email available.'); 
    }
  };

  return (
    <div className="cor-email-categorization">
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
          <Spin tip="Loading..." />
        </div>
 
      ) : (
        <>
          <div className="cor-email-categorization-header">
            <Text strong>From:</Text> <Text>{data?.mailbox_detail?.fromAddr}</Text>
          </div>
          <div className="cor-email-categorization-subject">
            <Text strong>Subject:</Text> <Text italic>{data?.mailbox_detail?.subject}</Text>
          </div>
          <div className="cor-email-categorization-content">
            <Text strong>Content:</Text>
            <div style={{ marginTop: 8 }}>
              <div
                className={`cor-chat-content-item-html`}
                dangerouslySetInnerHTML={{ __html: data?.mailbox_detail?.html || '' }}
              />
              <p>
                {/* Thanks, */}
                <br />
                {/* Jane Smith */}
              </p>
            </div>
          </div>
          <Divider />
          <div className="cor-email-categorization-category">
            <div>This email best belongs in:</div>
            <Radio.Group onChange={handleCategoryChange} value={category} className="radio-group">
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {categories.map((cat) => (
                  <div key={cat.id} style={{ width: '33%' }}>
                    <Radio value={cat.category}>{cat.category}</Radio>
                  </div>
                ))}
              </div>
            </Radio.Group>
          </div>
          <div className="cor-email-categorization-footer">
            <Button onClick={handlePreviousEmail}>Previous Email</Button>
            <Text type="secondary">{data?.current} of {data?.total} emails left</Text>
            <Button type="primary" onClick={handleNextEmail}>Next Email</Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailCategorization;
